import { createSlice } from "@reduxjs/toolkit";

const INITAIL_STATE = {
	activeMenu: "",
};

const adminSlice = createSlice({
	name: "admin",
	initialState: INITAIL_STATE,
	reducers: {
		updateActiveMenu: (state, action) => {
			state.activeMenu = action.payload;
		},
	},
});

export const { updateActiveMenu } = adminSlice.actions;
export default adminSlice.reducer;
