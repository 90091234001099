import React from "react";
import styled from "styled-components";
import action from "../../../assets/images/more.svg";

function ProductActionButton({ data, selectedProduct, setSelectedProduct }) {
	return (
		<Container>
			<Button
				onClick={() => {
					setSelectedProduct(data.id);
				}}
			>
				<img src={action} alt="" />
			</Button>
		</Container>
	);
}

export default ProductActionButton;
const Container = styled.div`
	position: relative;
`;
const Button = styled.span`
	display: block;
	width: 30px;
	transition: 0.1s;
	img {
		display: block;
		width: 100%;
	}
	:hover {
		opacity: 0.5;
	}
`;
