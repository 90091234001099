import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../constants";
import BankDetails from "../includes/Settings/BankDetails";
import ShopDetails from "../includes/Settings/ShopDetails";
import { updateActiveMenu } from "../store/reducers/AdminReducer";

function Settings() {
	const [activeMenu, setActiveMenu] = useState("shopDetails");
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("settings"));
	}, []);
	return (
		<Container>
			<Wrapper>
				<TopSection>
					<Title>Settings</Title>
					<ButonSection>
						<ShopDetailsBtn
							className={activeMenu === "shopDetails" && "active"}
							onClick={() => setActiveMenu("shopDetails")}
						>
							Shop Details
						</ShopDetailsBtn>
						<BankDetailsBtn
							className={activeMenu === "bankDetails" && "active"}
							onClick={() => setActiveMenu("bankDetails")}
						>
							Bank Account Details
						</BankDetailsBtn>
					</ButonSection>
				</TopSection>
				<InputSection>
					{activeMenu === "shopDetails" ? (
						<ShopDetails />
					) : activeMenu === "bankDetails" ? (
						<BankDetails />
					) : null}
				</InputSection>
			</Wrapper>
		</Container>
	);
}

export default Settings;
const Container = styled.div``;
const Wrapper = styled.div`
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	/* margin-top: 0px; */
`;
const TopSection = styled.div``;
const Title = styled.h2`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
	font-family: dm_sans_reg;
`;
const ButonSection = styled.div`
	background-color: #fff;
	padding: 10px 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	margin: 10px 0;
	border-radius: 5px;
`;
const ShopDetailsBtn = styled.span`
	padding: 6px 16px;
	display: block;
	border-radius: 3px;
	transition: 0.3s;
	font-size: 14px;
	text-transform: capitalize;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
	&.active {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
	&:hover {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
`;
const BankDetailsBtn = styled.span`
	padding: 6px 16px;
	display: block;
	border-radius: 3px;
	transition: 0.3s;
	font-size: 14px;
	text-transform: capitalize;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
	&.active {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
	&:hover {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
`;
const InputSection = styled.div``;
