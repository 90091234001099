import "./assets/css/App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./components/routers/MainRouter";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
	return (
		<div className="App">
			
			
			<Router>
				<MainRouter />	
			</Router>
		</div>
	);
};

export default App;
