import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import ProductTable from "../../includes/Products/ProductTable";
import Pagination from "../../general/Pagination";
import { Link } from "react-router-dom";

function Product() {
	const [searchValue, setSearchValue] = useState("");
	const [products, setProducts] = useState([
		{
			id: 1,
			name: "Product A",
			stock: 10,
			category: "Category 1",
			commission: 5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
		},
		{
			id: 2,
			name: "Product B",
			stock: 15,
			category: "Category 2",
			commission: 7.5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+B",
		},
		{
			id: 3,
			name: "Product C",
			stock: 20,
			category: "Category 1",
			commission: 10,
			status: "Inactive",
			image: "https://via.placeholder.com/150x150.png?text=Product+C",
		},
		{
			id: 4,
			name: "Product A",
			stock: 10,
			category: "Category 1",
			commission: 5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
		},
		{
			id: 5,
			name: "Product B",
			stock: 15,
			category: "Category 2",
			commission: 7.5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+B",
		},
		{
			id: 6,
			name: "Product C",
			stock: 20,
			category: "Category 1",
			commission: 10,
			status: "Inactive",
			image: "https://via.placeholder.com/150x150.png?text=Product+C",
		},
		{
			id: 7,
			name: "Product A",
			stock: 10,
			category: "Category 1",
			commission: 5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
		},
		{
			id: 8,
			name: "Product B",
			stock: 15,
			category: "Category 2",
			commission: 7.5,
			status: "Active",
			image: "https://via.placeholder.com/150x150.png?text=Product+B",
		},
		{
			id: 9,
			name: "Product C",
			stock: 20,
			category: "Category 1",
			commission: 10,
			status: "Inactive",
			image: "https://via.placeholder.com/150x150.png?text=Product+C",
		},
	]);

	const handleSearchInputChange = (event) => {
		setSearchValue(event.target.value);
	};
	return (
		<Container>
			<TopSection>
				<Title>Products</Title>
				<RightSection>
					<SearchContainer>
						<SearchIcon />
						<SearchBox
							type="text"
							value={searchValue}
							onChange={handleSearchInputChange}
							placeholder="Search..."
						/>
					</SearchContainer>
					<AddProductButton to={"add-product/"}>
						<AddProductIcon />
						<AddProductText>Add Product</AddProductText>
					</AddProductButton>
				</RightSection>
			</TopSection>
			<TableSection>
				<ProductTable products={products} />
			</TableSection>
			<Pagination />
		</Container>
	);
}

export default Product;
const Container = styled.div`
	position: relative;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Title = styled.h2`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 18px;
`;
const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
`;

const SearchContainer = styled.div`
	position: relative;
	background-color: #fff;
	width: 300px;
	border-radius: 5px;
`;

const SearchIcon = styled(SearchOutlinedIcon)`
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	color: ${COLORS.SECONDARY_TEXT};
	margin-right: 10px;
`;

const SearchBox = styled.input`
	padding-left: 40px;
	width: 100%;
	height: 40px;
	border: 1px solid #ffffff;
	border-radius: 4px;
	font-size: 16px;
	color: ${COLORS.PRIMARY_TEXT};
	background-color: transparent;
	outline: none;
	&::placeholder {
		color: ${COLORS.SECONDARY_TEXT};
	}

	&:focus {
		outline: none;
	}
`;
const AddProductButton = styled(Link)`
	padding: 0px 20px;
	height: 40px;
	border-radius: 5px;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);

	border: none;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;

const AddProductIcon = styled(AddOutlinedIcon)`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
`;

const AddProductText = styled.p`
	font-size: 16px;
	font-weight: bold;
	color: ${COLORS.PRIMARY_TEXT};
`;
const TableSection = styled.div`
	background-color: #fff;
	overflow-y: scroll;
	margin-top: 20px;
	padding: 20px;
	padding-bottom: 90px;
	height: calc(100vh - 170px);
`;
