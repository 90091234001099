import React, { useEffect, useState } from "react";
import styled from "styled-components";
import action from "../../../assets/images/more.svg";
import { COLORS } from "../../../constants";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import OutsideClick from "../../general/OutSideClick.jsx";

const OrderActionsButton = ({
	data,
	selectedOrder,
	setSelectedOrder,
	handleConfirmModal,
	handleCancelModal,
}) => {
	const [showOptions, setShowOptions] = useState(false);

	const options = () => {
		switch (data.status) {
			case "not_confirmed":
				return [
					{
						label: "Confirm Order",
						color: "#009262",
						function: handleConfirmModal,
						icon: <DoneIcon />,
					},
					{
						label: "Cancel Order",
						color: "#E01507",
						function: handleCancelModal,
						icon: <CloseIcon />,
					},
				];
			case "shipped":
				return [
					{
						label: "Download Slip",
						color: "#0A0A0A",
						function: "",
						icon: <FileDownloadOutlinedIcon />,
					},
					{
						label: "Cancel Order",
						color: "#E01507",
						function: handleCancelModal,
						icon: <CloseIcon />,
					},
				];
			case "confirm":
				return [
					{
						label: "Ready to Ship",
						function: "",
						color: "#0A0A0A",
						icon: <LocalShippingOutlinedIcon />,
					},
					{
						label: "Download Slip",
						color: "#0A0A0A",
						function: "",
						icon: <FileDownloadOutlinedIcon />,
					},
					{
						label: "Cancel Order",
						color: "#E01507",
						function: handleCancelModal,
						icon: <CloseIcon />,
					},
				];
			default:
				return [];
		}
	};

	const clearSelectedOrder = () => {
		setSelectedOrder("");
	};
	useEffect(() => {
		return () => setSelectedOrder("");
	}, []);

	return (
		<Container>
			{data.status === "canceled" ? (
				""
			) : (
				<Button
					onClick={() => {
						setSelectedOrder(data.id);
					}}
				>
					<img src={action} alt="" />
				</Button>
			)}

			{selectedOrder === data.id ? (
				<OutsideClick onClickOutside={clearSelectedOrder}>
					<MenuDraw>
						{options().map((data) => (
							<OptionButton
								color={data.color}
								onClick={data.function}
							>
								<Icon>{data.icon}</Icon>
								<Label>{data.label}</Label>
							</OptionButton>
						))}
					</MenuDraw>
				</OutsideClick>
			) : null}
		</Container>
	);
};

export default OrderActionsButton;
const Container = styled.div`
	position: relative;
`;
const Button = styled.span`
	display: block;
	width: 30px;
	transition: 0.1s;
	margin-left: auto;
	img {
		display: block;
		width: 100%;
	}
	:hover {
		opacity: 0.5;
	}
`;

const MenuDraw = styled.span`
	display: block;
	width: 200px;
	padding: 16px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -100px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	border-radius: 5px;
`;
const OptionButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px 0;
	border-bottom: 2px solid ${COLORS.SECONDARY};
	color: ${(props) => props.color};
	transition: 0.3s;
	border-radius: 5px;
	:last-child {
		border-bottom: none;
	}
	:hover {
		opacity: 0.8;
		color: #fff;
		background-color: ${(props) => props.color};
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	color: inherit;
	width: 16px;
	svg {
		display: block;
		width: 100%;
	}
	/* img {
		display: block;
		width: 100%;
	} */
`;
const Label = styled.p`
	font-size: 14px;
	color: inherit;
	margin-left: 5px;
`;
