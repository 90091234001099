import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import trash from "../../../assets/images/trash.svg";
import edit from "../../../assets/images/edit.svg";

const AttributeTable = ({ attributes, handleModal }) => {
	const [selectedProduct, setSelectedProduct] = useState("");
	return (
		<Table>
			<thead>
				<TableRow>
					<TableHeader className="slno">Sl No.</TableHeader>
					<TableHeader>Title</TableHeader>
					<TableHeader>Value</TableHeader>

					<TableHeader>Action</TableHeader>
				</TableRow>
			</thead>
			<tbody>
				{attributes.map((attribute, index) => (
					<TableRow key={attribute.id}>
						<TableData className="slno">{index + 1}</TableData>
						<TableData>{attribute.title}</TableData>
						<TableData>{attribute.value}</TableData>

						<TableData>
							<ActionButtons>
								<EditButton onClick={handleModal}>
									<img src={edit} alt="" />
								</EditButton>
								<DeleteButton>
									<img src={trash} alt="" />
								</DeleteButton>
							</ActionButtons>
						</TableData>
					</TableRow>
				))}
			</tbody>
		</Table>
	);
};

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	min-width: 600px;
`;

const TableRow = styled.tr`
	padding: 10px 0;
	border-bottom: 1px solid ${COLORS.SECONDARY};
	.slno {
		max-width: 50px;
	}
`;

const TableHeader = styled.th`
	color: ${COLORS.SECONDARY_TEXT};
	font-size: 16px;
	font-weight: 500;
	padding: 12px;
	text-align: left;
	:last-child {
		text-align: end;
	}
`;

const TableData = styled.td`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 14px;
	padding: 20px 12px;
	text-align: left;
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const ActionButton = styled.button`
	background-color: transparent;
	border: none;
	color: #666;
	cursor: pointer;
	font-size: 14px;
	margin-right: 10px;
	outline: none;
	padding: 5px;

	&:hover {
		color: #333;
	}
`;
const EditButton = styled.span`
	width: 20px;
	display: block;
	margin-right: 20px;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
	}
`;
const DeleteButton = styled.span`
	width: 20px;
	cursor: pointer;
	display: block;
	img {
		display: block;
		width: 100%;
	}
`;

export default AttributeTable;
