import React from "react";
import styled from "styled-components";
import { COLORS } from "../../constants";
import ReactPaginate from "react-paginate";

function Pagination() {
	const handlePageClick = (event) => {
		const newOffset = event.selected;
		console.log(
			`User requested page number ${
				event.selected + 1
			}, which is offset ${newOffset}`
		);
		// setItemOffset(newOffset);
	};
	return (
		<Container>
			<LeftSection>Showing 1 to 5 of 710 entries</LeftSection>
			<RightSection>
				<ReactPaginate
					breakLabel="..."
					nextLabel=" >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={2}
					pageCount={20}
					previousLabel="< "
					renderOnZeroPageCount={null}
				/>
			</RightSection>
		</Container>
	);
}

export default Pagination;
const Container = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0px;
	background-color: ${COLORS.PRIMARY};
	height: 60px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const LeftSection = styled.p`
	color: #48538d;
	font-size: 14px;
`;
const RightSection = styled.div`
	ul {
		display: flex;
		align-items: center;
		.selected {
			a {
				background: linear-gradient(
					340.8deg,
					#ffdf17 18.2%,
					#fff79e 167.34%
				) !important;
			}
		}
		li {
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				height: 35px;
				width: 35px;
				min-width: 35px;
				background-color: #f5f7fb;
				margin-right: 10px;
				cursor: pointer;
			}
			&:last-child {
				a {
					margin-right: 0px;
				}
			}
			color: #48538d;
		}

		.next,
		.previous {
			a {
				background-color: #fff;
				font-size: 22px;
			}
		}
		.disabled {
			a {
				opacity: 0.5;
				cursor: not-allowed;
				background-color: #fff;
				font-size: 22px;
			}
		}
	}
`;
