import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import CoundCard from "../includes/Orders/CountCard";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import money from "../../assets/images/earning.svg";
import { COLORS } from "../../constants";
import TranstionList from "../includes/Payment/TranstionList";
import PaymentRequestModal from "../includes/Payment/Modals/PaymentRequestModal";

function Payments() {
	const [isPaymentModal, setPaymentModal] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("payments"));
	}, []);

	const closeModal = () => {
		setPaymentModal(false);
	};
	return (
		<Container>
			<PaymentRequestModal
				isModal={isPaymentModal}
				onClose={closeModal}
			/>
			<Title>Payment</Title>
			<TopSection>
				<RevenuCard>
					<CoundCard icon={money} label="Revenue" count="₹1,440" />
				</RevenuCard>
				<PaymentSection>
					<ContentSection>
						<Label>Bank Details </Label>
						<BankDetails>
							<BankName>Bank Name: National Bank</BankName>
							<AccountNumber>
								Account Number:7890, Swfit Code:7787H
							</AccountNumber>
						</BankDetails>
					</ContentSection>
					<ButtonSection>
						<AddBankButton>Add Bank Details</AddBankButton>
						<RequestPaymentButton
							onClick={() => setPaymentModal(true)}
						>
							Request Payments
						</RequestPaymentButton>
					</ButtonSection>
				</PaymentSection>
			</TopSection>
			<TranstionList />
		</Container>
	);
}

export default Payments;
const Container = styled.div``;
const Title = styled.h3`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
	margin-bottom: 10px;
	font-family: "dm_sans_reg";
`;
const TopSection = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 10px;
`;
const RevenuCard = styled.div`
	background-color: #fff;
	border-radius: 8px;
	flex: 1;
	max-width: 350px;
`;
const PaymentSection = styled.div`
	background-color: #fff;
	padding: 20px;
	flex: 1;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
`;
const ContentSection = styled.div``;

const Label = styled.p`
	color: ${COLORS.SECONDARY_TEXT};
	font-size: 14px;
	margin-bottom: 10px;
`;
const BankDetails = styled.span`
	display: block;
`;
const BankName = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 15px;
`;
const AccountNumber = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 15px;
`;
const ButtonSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
`;
const AddBankButton = styled.span`
	color: #e48900;
	border: 1px solid #e48900;
	padding: 8px 0px;
	background-color: #f8f8f8;
	border-radius: 5px;
	cursor: pointer;
	font-size: 13px;
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		opacity: 0.8;
	}
`;
const RequestPaymentButton = styled.span`
	color: ${COLORS.PRIMARY_TEXT};
	border-radius: 5px;
	padding: 10px 0px;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	cursor: pointer;
	font-size: 13px;
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		opacity: 0.8;
	}
`;
