import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../api'; // Adjust the import path accordingly

const useGetApi = (url, params = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(url, { params });
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url, params]);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data, fetchData, params]);

  return { loading, data, error, refetch: fetchData };
};

export default useGetApi;
