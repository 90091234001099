import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

function SelectCategory({ setCurrentPage }) {
	const { control, handleSubmit } = useForm();

	const onSubmit = (data) => {
		console.log(data);
		setCurrentPage("add-product");
	};
	return (
		<Container>
			<TopSection>
				<LeftSection>
					<Title>Add Products</Title>
					<Label>Products/add products</Label>
				</LeftSection>
				<ProgressBar>
					<ProgressLabel>1</ProgressLabel>
					<ProgressLabel>2</ProgressLabel>
				</ProgressBar>
			</TopSection>
			<SelectContainer>
				<SubTitle>Categories</SubTitle>
				<SelectSection onSubmit={handleSubmit(onSubmit)}>
					<SelectOption>
						<SelectLabel>Select Category</SelectLabel>
						<Controller
							name="select1"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<select value={value} onChange={onChange}>
									<option value="">Select</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
								</select>
							)}
						/>
					</SelectOption>
					<SelectOption>
						<SelectLabel>Category 1</SelectLabel>
						<Controller
							name="select2"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<select value={value} onChange={onChange}>
									<option value="">Select</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
								</select>
							)}
						/>
					</SelectOption>
					<SelectOption>
						<SelectLabel>Category 2</SelectLabel>
						<Controller
							name="select3"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<select value={value} onChange={onChange}>
									<option value="">Select</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
								</select>
							)}
						/>
					</SelectOption>
					<SelectOption>
						<SelectLabel>Category 4</SelectLabel>
						<Controller
							name="select4"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<select value={value} onChange={onChange}>
									<option value="">Select</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
								</select>
							)}
						/>
					</SelectOption>
					<ButtonContainer>
						<CancelButton>Cancel</CancelButton>
						<SubmitButton type="submit">Submit</SubmitButton>
					</ButtonContainer>
				</SelectSection>
			</SelectContainer>
		</Container>
	);
}

export default SelectCategory;
const Container = styled.div`
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	margin-top: 30px;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
`;
const LeftSection = styled.div``;

const Title = styled.h2`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
`;
const Label = styled.p`
	color: ${COLORS.SECONDARY_TEXT};
	font-size: 13px;
`;
const ProgressBar = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
const ProgressLabel = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	border: 1px solid ${COLORS.THEME_COLOR};
	border-radius: 5px;
	position: relative;
	background-color: ${COLORS.PRIMARY};
	:last-child {
		color: #d4d4d4;
		border: 1px solid ${COLORS.SECONDARY};
		margin-left: 50px;
		::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border-bottom: 2px dashed ${COLORS.THEME_COLOR};
			width: 50px;
			left: -50px;
			box-sizing: border-box;
		}
	}
`;
const SelectContainer = styled.div`
	padding: 30px;
	background-color: ${COLORS.PRIMARY};
	margin-top: 20px;
`;
const SubTitle = styled.h3`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
	padding-bottom: 20px;
	border-bottom: 2px solid ${COLORS.SECONDARY};
	margin-bottom: 30px;
`;

const SelectSection = styled.form`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
`;

const SelectLabel = styled.label`
	justify-self: flex-start;
	color: ${COLORS.SECONDARY_TEXT};
	min-width: 120px;
`;

const SelectOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	select {
		padding: 14px 10px;
		background-color: ${COLORS.SECONDARY};
		width: 100%;
		max-width: 500px;
		border: 2px solid #eeeeee;
		border-radius: 5px;

		position: relative;
		:focus {
			border-color: #eab600;
		}
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
`;
