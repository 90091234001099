import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../constants";
import Header from "../general/Header";
import PageNotFound from "../general/PageNotFound";
import Sidebar from "../general/SideBar";
import Attribute from "../pages/Attribute";
import DashBoard from "../pages/DashBoard";
import Home from "../pages/Home";
import Inventory from "../pages/Inventory";
import Payments from "../pages/Payments";
import Settings from "../pages/Settings";
import OrdersRouter from "./OrdersRouter";
import ProductRouter from "./ProductRouter";
import useGetApi from "../../hooks/useGetApi";
import axiosInstance from "../../api";
import { getToken } from "../utils/HelperFunctions";
import EditProfile from "../pages/EditProfile";
import Vendors from "../pages/Vendors";
import VendorProduct from "../pages/VendorProduct";
import VendorOrders from "../pages/VendorOrders";
import PaymentRequest from "../pages/PaymentRequest";

const AppRouter = () => {
  const token = getToken();
  return (
    <Container>
      <Header />
      <MainContainer>
        <Sidebar />
        <DashboardContainer>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/vendor-products" element={<VendorProduct />} />
            <Route path="/vendor-orders" element={<VendorOrders />} />
            <Route path="/payment-request" element={<PaymentRequest />} />
            <Route path="/orders/*" element={<OrdersRouter />} />
            <Route path="/products/*" element={<ProductRouter />} />
            <Route path="/attributes" element={<Attribute />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </DashboardContainer>
      </MainContainer>
    </Container>
  );
};

export default AppRouter;
const Container = styled.div``;
const MainContainer = styled.div`
  display: flex;
`;
const DashboardContainer = styled.div`
  flex: 1;
  background-color: ${COLORS.SECONDARY};
  padding: 20px;
  height: calc(100vh - 70px);
  overflow-y: scroll;
`;
