import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Order from "../pages/Order";
import AddProduct from "../pages/Products/AddProduct";
import Product from "../pages/Products/Product";
import { updateActiveMenu } from "../store/reducers/AdminReducer";

function ProductRouter() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("products"));
	}, []);
	return (
		<Container>
			<Routes>
				<Route exact path="/" element={<Product />} />
				<Route exact path="/add-product" element={<AddProduct />} />
			</Routes>
		</Container>
	);
}

export default ProductRouter;
const Container = styled.div`
	height: 100%;
	overflow-y: scroll;
`;
