import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Authentication from "../general/Auth/Authentication.jsx";
import PageNotFound from "../general/PageNotFound";

const AuthRouter = () => {
	return (
		<>
			<Routes>
				<Route
					path="/"
					element={<Navigate to="/auth/?p=enter-phone" />}
				/>
				<Route path="/auth/" element={<Authentication />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</>
	);
};

export default AuthRouter;
