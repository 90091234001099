import React from "react";
import styled from "styled-components";
import supports from "../../../assets/images/support.svg";
import Mail from "../../../assets/images/mail.svg";




function Supporter() {
	return  <Section id="Supports">
          
            <div className="support">
                <div className="sup-image">
                    <img src={supports} alt="supports" />
                </div>
                <div className="content">
                   <h1 className="heading">Onerupee Supplier Support Available  
                   <span className="yellow">24/7</span> </h1>
                   <p className="text">Onerupee supplier support is available to solve all your doubts
                   and issues before and after you start your online selling business.</p>
                    <div className="mail">
                        <div className="icon">
                            <img src={Mail} alt="Mail" />
                        </div>
                        <div className="mail-text">
                            <p className="txt">You can Reach Out</p>
                            <p className="mail-id">sample@gmail.com</p>
                        </div> 
                    </div>
                    <div className="enquiry">
                        <a href="#" className="btn3">Enquiry Now</a>
                    </div>
                </div>
            </div>
    
	</Section>;
    
}

export default Supporter;


const Section = styled.section`
  background-color: white;
  margin-top: -13px;
  padding: 3rem 0;
  .support{
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 5%;
    }
    .heading{
        
        max-width: 100%;
        color: black;
        font-style: normal;
        font-weight: 500;
        font-size: 41px;
        line-height: 50px;
        text-align: left;
        word-spacing: 3px;
        margin-top:30px;
      .yellow{
           color: #EAB600;
           font-style: normal;
           font-weight: 600;
           font-size: 45px;
           line-height: 68px;
          margin-left:10px;
        }
    }
    .text{
        width: 60%;
        max-width:100%;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        color: #8d8b8b;
    }
    .mail{
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }
    .txt{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 23px;
        color: #1a1919;
    }
    .mail-id{
        color: #EAB600;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
    }
    .enquiry{
        background: linear-gradient(340.8deg, #FFDF17 18.2%, #FFF79E 167.34%);
        border: none;
        border-radius: 8px;
        align-items: center;
        padding: 11px 33px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        align-self: start;
        margin-top: 2%;
        width: 25%; 
        .btn3{
            color: black;
        }
    }

    .sup-image{
      width: 55%;
      margin-left:9%;
        img{
            width: 100%;   
        }
    }
    @media only screen and (max-width: 780px){
       .support{
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-around;
            align-items: center;
            gap: 5%;
        }
        .heading{
        padding: 0;
        max-width: 100%;
       
        font-weight: 500;
        font-size: 41px;
        line-height: 50px;
        text-align: left;
        word-spacing: 3px;
        margin-top:30px;
      .yellow{
           color: #EAB600;
           font-style: normal;
           font-weight: 600;
           font-size: 45px;
           line-height: 68px;
          margin-left:10px;
        }
    }

    }
    @media only screen and (max-width: 560px){
       .support{
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-around;
            align-items: center;
            gap: 5%;
        }
        .heading{
            max-width: 100%;
            margin-left:30px;
            font-size: 32px;
            line-height: 20px;
            text-align: left;
           .yellow{
                font-size: 32px;
            }
        }
        .text{
            width: 80%;
            max-width:100%;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 23px;
            color: #8d8b8b;
            margin-left:30px;
        }   
        .mail{
            display: flex;
            gap: 20px;
            margin-left:30px;
        }
        .enquiry{
            margin-top: 4%;
            width: 45%;
            margin-left:30px; 
        }
        .sup-image{
            width: 85%;
            margin-left:5%;
           img{
               width: 100%;  
               margin-top: 40px; 
            }
        }
    }
`;

