import React from "react";
import styled from "styled-components";
import CountCard from "../includes/Orders/CountCard";
import time from "../../assets/images/time.svg";
import cancel from "../../assets/images/clipboard-close.svg";
import returnIcon from "../../assets/images/return.svg";
import { COLORS } from "../../constants";
import OrderDetailsTable from "../includes/Orders/OrderDetailsTable";
import Pagination from "../general/Pagination";

function Order() {
	return (
		<Container>
			<Title>Orders</Title>
			<CountSection>
				<CountCard icon={time} label="pending orders" count="227" />
				<CountCard icon={cancel} label="cancel orders" count="109" />
				<CountCard
					icon={returnIcon}
					label="returned orders"
					count="23"
				/>
			</CountSection>
			<OrderTableSection>
				<FilterMenu>
					<Menu className="active">All orders</Menu>
					<Menu>Return orders</Menu>
					<Menu>Canceled orders</Menu>
				</FilterMenu>
				<OrderDetailsTable />
				<Pagination />
			</OrderTableSection>
		</Container>
	);
}

export default Order;
const Container = styled.div`
	height: 100%;
`;
const Title = styled.h2`
	font-size: 18px;
	margin-bottom: 10px;
`;
const CountSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 16px;
	margin-bottom: 16px;
`;
const OrderTableSection = styled.div`
	overflow: scroll;
	position: relative;
	/* position: sticky;
	top: 0px;
	z-index: 999; */
`;
const FilterMenu = styled.div`
	background-color: ${COLORS.PRIMARY};
	padding: 10px 16px;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	margin-bottom: 10px;
	position: -webkit-sticky; /* Safari */
`;
const Menu = styled.span`
	padding: 6px 16px;
	display: block;
	border-radius: 3px;
	transition: 0.3s;
	font-size: 14px;
	text-transform: capitalize;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
	&.active {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
	&:hover {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
`;
