import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../../../constants";
import OutsideClick from "../../../general/OutSideClick";

function AddAttributesModal({ isModal, handleModal }) {
	return (
		<ModalWrapper isModal={isModal}>
			<ModalContent>
				<ModalTopSection>
					<Title>Add New Attributes</Title>
					<CloseButton onClick={handleModal}>
						<CloseIcon />
					</CloseButton>
				</ModalTopSection>
				<InputSection>
					<Label htmlFor="attribute_name">Title</Label>
					<Input
						// onChange={handleOnchange}
						type="text"
						id="attribute_name"
						placeholder="Enter title"
					/>
				</InputSection>
				<InputSection>
					<Label htmlFor="attribute_value">Values</Label>
					<Textarea
						// onChange={handleOnchange}
						type="text"
						id="attribute_value"
						placeholder="Enter values"
					/>
					<Description>
						Add multiple value separating using commas (,)
					</Description>
				</InputSection>
				<ButtonContainer>
					<CancelButton onClick={handleModal}>Cancel</CancelButton>
					<ConfirmButton onClick={handleModal}>Submit</ConfirmButton>
				</ButtonContainer>
			</ModalContent>
		</ModalWrapper>
	);
}

export default AddAttributesModal;
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00011f33;
	backdrop-filter: blur(3px);
	display: ${(props) => (props.isModal ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	z-index: 99;
`;

const ModalContent = styled.div`
	background-color: #fff;
	padding: 30px;
	border-radius: 4px;
	width: 90%;
	max-width: 600px;
`;
const ModalTopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid ${COLORS.SECONDARY};
	margin-bottom: 30px;
`;

const Title = styled.h2`
	font-size: 20px;
	color: ${COLORS.PRIMARY_TEXT};
`;

const CloseButton = styled.button`
	background: none;
	border: none;
	color: #555;
	cursor: pointer;
	font-size: 1.25rem;

	&:hover {
		color: #000;
	}
`;
const InputSection = styled.div`
	margin-top: 1.5rem;
`;

const Label = styled.label`
	font-size: 15px;
	font-weight: bold;
	color: ${COLORS.SECONDARY_TEXT};
`;

const Input = styled.input`
	display: block;
	width: 100%;
	border: none;
	border: 1px solid ${COLORS.SECONDARY};
	margin-top: 0.5rem;
	padding: 0.25rem 0;
	height: 50px;
	padding: 0 10px;
	border-radius: 6px;
	font-size: 16px;
	background-color: #f3f3f3;

	&:focus {
		outline: none;
	}
`;

const Textarea = styled.textarea`
	display: block;
	width: 100%;
	border: none;
	border: 1px solid ${COLORS.SECONDARY};
	margin-top: 0.5rem;

	height: 100px;
	padding: 10px;
	border-radius: 6px;
	font-size: 16px;
	background-color: #f3f3f3;

	&:focus {
		outline: none;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2.5rem;
`;

const CancelButton = styled.span`
	display: block;
	color: ${COLORS.SECONDARY_TEXT};
	border: none;
	border-radius: 4px;
	padding: 0.5rem 1rem;
	cursor: pointer;
	margin-right: 1rem;

	&:hover {
		opacity: 0.8;
	}
`;

const ConfirmButton = styled.button`
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	color: ${COLORS.PRIMARY_TEXT};
	border: none;
	border-radius: 4px;
	padding: 10px 35px;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;
const Description = styled.p`
	font-size: 12px;
	margin-top: 5px;
	color: ${COLORS.SECONDARY_TEXT};
`;
