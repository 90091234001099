import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/w_logo.png";
import img from "../../../assets/images/sign_up_image.png";
import bg from "../../../assets/images/sign_bg.png";

function SIgnInRightSection() {
	return (
		<RightSection>
			{" "}
			<CompanyLogo src={logo} alt="Company Logo" />
			<CompanyLabel>Sell your products throw onerupee</CompanyLabel>
			<ImageCover>
				{" "}
				<CompanyImage src={img} alt="Company Image" />
			</ImageCover>
		</RightSection>
	);
}

export default SIgnInRightSection;
const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-size: cover;
	/* align-items: center; */
	flex: 1;
	background: url(${bg}) no-repeat;
	background-size: cover;
	padding: 50px 70px;
	padding-top: 100px;
	height: 100vh;
`;

const CompanyLogo = styled.img`
	width: 200px;
	margin-bottom: 10px;
`;

const CompanyLabel = styled.label`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
	color: #d4d4d4;
`;

const CompanyImage = styled.img`
	display: block;
	width: 100%;
`;
const ImageCover = styled.div`
	margin: 0 -70px;
`;
