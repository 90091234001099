import React from "react";
import Navbar from "./Navbar";
import SpotLight from "./SpotLight";
import Card from "./Card";
import Working from "./Working";
import Supporter from "./Supporter";
import Footer from "./Footer";

function LandingPage() {
	return (
		<div>
			<Navbar />
			<SpotLight />
			<Card />
			<Working />
			<Supporter />
			<Footer />
		</div>
	);
}

export default LandingPage;
