import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { COLORS } from "../../constants";
import { Link } from "react-router-dom";
import Pagination from "../general/Pagination";
import AttributeTable from "../includes/Attributes/AttributeTable";
import AddAttributesModal from "../includes/Attributes/Modals/AddAttributesModal";

function Attribute() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("attributes"));
	}, []);
	const [isModal, setModal] = useState(false);

	const handleModal = () => {
		setModal((prev) => !prev);
	};
	const [attributes, setAttributes] = useState([
		{
			id: 1,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 2,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 3,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 4,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 5,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 6,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 7,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 8,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 9,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
		{
			id: 10,
			title: "Jhon Miller Shirt",
			value: "Size s, Color Red",
		},
	]);

	return (
		<Container>
			<AddAttributesModal isModal={isModal} handleModal={handleModal} />
			<TopSection>
				<Title>Attributes</Title>
				<AddProductButton onClick={handleModal}>
					<AddProductIcon />
					<AddProductText onCLick={handleModal}>
						Add Attribute
					</AddProductText>
				</AddProductButton>
			</TopSection>
			<ContentSection>
				<TableContainer>
					<AttributeTable
						attributes={attributes}
						handleModal={handleModal}
					/>
				</TableContainer>
			</ContentSection>
			<Pagination />
		</Container>
	);
}

export default Attribute;
const Container = styled.div`
	position: relative;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
`;
const Title = styled.h3`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
`;
const AddProductButton = styled.button`
	padding: 0px 20px;
	height: 40px;
	border-radius: 5px;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);

	border: none;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;

const AddProductIcon = styled(AddOutlinedIcon)`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
`;

const AddProductText = styled.span`
	display: block;
	font-size: 16px;
	font-weight: bold;
	color: ${COLORS.PRIMARY_TEXT};
`;
const ContentSection = styled.div`
	padding: 20px;
	background-color: #fff;
	margin-top: 20px;
	border-radius: 5px;
	height: calc(100vh - 170px);
	padding-bottom: 90px;
	overflow-y: scroll;
`;
const TableContainer = styled.div``;
