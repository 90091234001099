import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/logo.svg";
import Social1 from "../../../assets/images/social1.svg";
import Social2 from "../../../assets/images/social2.svg";
import Social3 from "../../../assets/images/social3.svg";
import Social4 from "../../../assets/images/social4.svg";
import Social5 from "../../../assets/images/social5.svg";
import CopyWrite from "../../../assets/images/copywrite.svg";






function Footer() {
	return  <FooterContainer>
        <div className="footer-content">
           <div className="center">
              <div className="social">
                 <ul className="social-media">
                    <li className="social-item"><img src={Social1} alt="Instagram" /></li>
                    <li className="social-item"><img src={Social2} alt="Facebook" /></li>
                    <li className="social-item"><img src={Social3} alt="Twitter" /></li>
                    <li className="social-item"><img src={Social4} alt="Linkedin" /></li>
                    <li className="social-item"><img src={Social5} alt="Youtube" /></li>
                 </ul>
              </div>
              <div className="footer-logo">
                 <img src={logo} alt="logo" />
              </div>
           </div>
           <div className="right">
              <p className="copy"><img src={CopyWrite} alt="CopyWrite" />2023 ONERUPEE. All Right Reserved</p>
           </div>
        </div>
          
    
	</FooterContainer>;
    
}

export default Footer;


const FooterContainer = styled.div`
   padding: 4rem 0;
   background-color: white;
   
  .footer-content{
     display: flex;
     justify-content: space-around;
     align-items: center;
     gap: 20%;
  }
  .center{
   display: flex;
   gap: 80%;
  
  }
  .social-media{
   display: flex;
   gap: 20px;
  }
  .social-item{
     
         img{
            height: 1.4rem;
        }
   }
   .footer-logo{

      img{
            height: 2.5rem;
        }
   }
   .copy{
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 58px;
      text-align: right;
      color: #747474;
   }
    
   @media only screen and (max-width: 780px){
      .footer-content{
         display: flex;
         flex-direction: column;
      }
      .center{
         display: flex;
         flex-direction: column-reverse;
         gap: 30px;
      }
      .social-media{
         display: flex;
         gap: 30px;
      }
      .social-item{
     
         img{
            height: 1.9rem;
         }
      }
      .footer-logo{
         margin-top:20px;
         img{
            height: 3.7rem;
         }
      }
      .copy{
         font-size: 17px;
         line-height: 58px;
         text-align: center;
         color: #747474;
         margin-top: 30px;
      }

   }
  
`;
