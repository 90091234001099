import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo.svg";



function Navbar() {
	return  <div>
        <Nav className="nav">
            <Left>
                <Logo>
                   <img src={logo} className="app_logo" alt="logo" />
                </Logo>
            </Left>
            <Right>
                <Link to="#">
                    <Button className="btn1">Start Selling</Button>
               </Link>
            </Right>
        </Nav>
	</div>;
    
}
export default Navbar;

const Nav = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 34px 100px;
   background-color: white;
   

   @media only screen and (max-width: 992px){
      position: relative;
      margin: 0;
      padding: 18px 10px;
   }
   

   @media only screen and (max-width: 480px){
    .app_logo{
    width: 60%;
    }
    .btn1{
        font-size: 12px;
    }
   }
`;
const Left = styled.div`
.app_logo{
    width: 80%;
}
`;
const Right = styled.div``;
const Logo =styled.h1`
    max-width: 231px;
    font-size:20px;
`;
const Button =styled.button`
  background: linear-gradient(340.8deg, #FFDF17 18.2%, #FFF79E 167.34%);
  border: none;
  border-radius: 8px;
  align-items: center;
  padding: 12px 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  .btn1{
      color: black;

     
  }
`;
