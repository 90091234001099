import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { updateUserData } from "../store/reducers/UserReducer";
import camera from "../../assets/images/Iconly.svg";

Modal.setAppElement("#root");

const EditProfile = () => {
    const dispatch = useDispatch();

    const { name, image } = useSelector((state) => state.user.userData);

    const [newName, setNewName] = useState(name);
    const [newImage, setNewImage] = useState(image);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const cropperRef = useRef(null);

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setNewImage(imageUrl);
            setModalIsOpen(true);
        }
    };
    const handleCropSave = () => {
        const cropper = cropperRef.current.cropper;
        const croppedCanvas = cropper.getCroppedCanvas();
        const croppedDataUrl = croppedCanvas.toDataURL("image/jpeg");
        setNewImage(croppedDataUrl);
        setCroppedImage(croppedDataUrl);
        setModalIsOpen(false);
    };

    const handleCancelCrop = () => {
        setModalIsOpen(false);
    };

    const handleCameraClick = () => {
        document.getElementById("file-input").click();
    };

    const handleSave = () => {
        dispatch(
            updateUserData({
                name: newName,
                image: croppedImage || newImage,
            })
        );
    };

    const handleCancel = () => {
        setNewName(name);
        setNewImage(image);
        setCroppedImage(null);
    };

    return (
        <Container>
            <Cover>
                <TopSection>
                    <h3>Edit Profile</h3>
                    <p>Home/Settings</p>
                </TopSection>
                <EditContainer>
                    <Top>
                        <h5>Profile Details</h5>
                    </Top>
                    <Bottom>
                        <ImageBox>
                            <RoundContainer>
                                {newImage ? (
                                    <ProfileImage
                                        src={croppedImage || newImage}
                                        alt="Uploaded Profile"
                                        style={{
                                            width: "100%",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            // border: "1px solid #ccc",
                                            border: "2px solid rgb(255, 230, 61)",
                                            background: "#eee",
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </RoundContainer>
                            <CameraIconContainer onClick={handleCameraClick}>
                                <img src={camera} alt="camera" />
                            </CameraIconContainer>
                            <UploadButton
                                id="file-input"
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                        </ImageBox>
                        <InputContainer>
                            <InputLabel>Name</InputLabel>
                            <div style={{ width: "60%" }}>
                                <input
                                    type="text"
                                    value={newName}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        background: "#eee",
                                    }}
                                />
                            </div>
                        </InputContainer>
                    </Bottom>
                    <ButtonContainers>
                        <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
                        <SaveBtn onClick={handleSave}>Save</SaveBtn>
                    </ButtonContainers>
                </EditContainer>
            </Cover>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCancelCrop}
                style={{
                    overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                    content: {
                        width: "500px",
                        margin: "auto",
                        borderRadius: "10px",
                        padding: "20px",
                        height: "450px",
                        zIndex: "10",
                    },
                }}
            >
                <h3
                    style={{
                        textAlign: "left",
                        marginBottom: "10px",
                        color: "#000",
                        fontSize: "16px",
                    }}
                >
                    Upload Profile Picture
                </h3>
                <Cropper
                    src={newImage}
                    style={{ height: 300, width: "100%" }}
                    aspectRatio={1}
                    guides={false}
                    ref={cropperRef}
                />
                <ModalButtons>
                    <CancelBtn onClick={handleCancelCrop}>Cancel</CancelBtn>
                    <SaveBtn onClick={handleCropSave}>Save</SaveBtn>
                </ModalButtons>
            </Modal>
        </Container>
    );
};

export default EditProfile;

/* STYLES */
const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    justify-content: center;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    cursor: pointer;
`;

const Cover = styled.div`
    width: 70%;
    /* height: 50vh; */
    /* background-color: #fff; */
`;
const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 15px;
`;

const TopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: #adadad;
        font-size: 12px;
    }
`;

const EditContainer = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #fff;
    margin-top: 10px;
`;

const Top = styled.div`
    padding: 20px 0px;
    border-bottom: 1px solid #eee;
`;

const Bottom = styled.div`
    padding: 30px 0px;
`;

const ImageBox = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
    position: relative;
`;

const RoundContainer = styled.div`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
`;
const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const DefaultImageText = styled.span`
    color: #777;
    font-size: 14px;
`;

const CameraIconContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 340px;
    background-color: #1565c0;
    border-radius: 50%;
    padding: 12px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 5;
`;

const UploadButton = styled.input`
    display: none;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
`;
const InputLabel = styled.label`
    color: #747474;
`;
const ButtonContainers = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CancelBtn = styled.div`
    width: 100px;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
    cursor: pointer;
`;

const SaveBtn = styled.div`
    width: 120px;
    background: #ffe63d;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
`;
