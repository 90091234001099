import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ResendOtp = () => {
	const [countDown, setCountDown] = useState(30);

	const handleResendClick = () => {
		console.log("otp sended");
		startCountdown();
	};

	const startCountdown = () => {
		setCountDown(30);
	};

	useEffect(() => {
		if (countDown > 0) {
			const timerId = setTimeout(() => setCountDown(countDown - 1), 1000);
			return () => clearTimeout(timerId);
		}
	}, [countDown]);

	return (
		<>
			<span>
				{countDown === 0 ? (
					<Button
						onClick={handleResendClick}
						disabled={countDown !== 0}
					>
						Resend OTP
					</Button>
				) : countDown < 10 ? (
					`Resent OTP in 0${countDown} sec`
				) : (
					"Resent OTP in " + countDown + "sec"
				)}
			</span>
		</>
	);
};

export default ResendOtp;
const Button = styled.span`
	border: none;
	padding: 8px 16px;
	cursor: pointer;
	color: #eab600;
`;
