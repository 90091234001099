// axiosSetup.js
import axios from 'axios';
import { getToken, logOut } from '../components/utils/HelperFunctions';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: 'https://api.oneruppee.com/api/v1/', 
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
  },
});


axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle response error
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Unauthorized access - redirecting to login');
        logOut()
      }
      if (error.response.status === 500) {
        console.error('Server error - please try again later');
      }
    } else if (error.request) {
      console.error('Network error - please check your internet connection');
    } else {
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
