import React, { useState } from "react";
import styled from "styled-components";
import ProductActionButton from "./ProductActionButton";

const ProductTable = ({ products }) => {
	const [selectedProduct, setSelectedProduct] = useState("");
	return (
		<Table>
			<thead>
				<TableRow>
					<TableHeader>Sl No.</TableHeader>
					<TableHeader>Image</TableHeader>
					<TableHeader>Product Name</TableHeader>
					<TableHeader>Stock</TableHeader>
					<TableHeader>Category</TableHeader>
					<TableHeader>Commission</TableHeader>
					<TableHeader>Status</TableHeader>
					<TableHeader>Action</TableHeader>
				</TableRow>
			</thead>
			<tbody>
				{products.map((product, index) => (
					<TableRow key={product.id}>
						<TableData>{index + 1}</TableData>
						<TableData>
							<ProductImage src={product.image} />
						</TableData>
						<TableData>{product.name}</TableData>
						<TableData>{product.stock}</TableData>
						<TableData>{product.category}</TableData>
						<TableData>{product.commission}</TableData>
						<TableData>{product.status}</TableData>
						<TableData>
							<ActionButtons>
								<ProductActionButton
									data={product}
									selectedProduct={selectedProduct}
									setSelectedProduct={setSelectedProduct}
								/>
							</ActionButtons>
						</TableData>
					</TableRow>
				))}
			</tbody>
		</Table>
	);
};

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1100px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
	color: #444;
	font-size: 16px;
	font-weight: 500;
	padding: 12px;
	text-align: left;
	:last-child {
		text-align: end;
	}
`;

const TableData = styled.td`
	color: #444;
	font-size: 14px;
	padding: 12px;
	text-align: left;
`;

const ProductImage = styled.img`
	height: 50px;
	width: 50px;
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const ActionButton = styled.button`
	background-color: transparent;
	border: none;
	color: #666;
	cursor: pointer;
	font-size: 14px;
	margin-right: 10px;
	outline: none;
	padding: 5px;

	&:hover {
		color: #333;
	}
`;

export default ProductTable;
