import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EnterOtp from "./EnterOtp";
import EnterPhone from "./EnterPhone";

function Authentication() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const param = urlParams.get("p");

	const navigate = useNavigate();
	function handleClick() {
		navigate("/auth/?p=enter-phone");
	}

	console.log(param);
	return (
		<Container>
			{param === "enter-phone" ? (
				<EnterPhone />
			) : param === "enter-otp" ? (
				<EnterOtp />
			) : null}
		</Container>
	);
}

export default Authentication;
const Container = styled.div``;
