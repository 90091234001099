import React from "react";
import styled from "styled-components";
import tick from "../../../assets/images/sucess.svg";

function ToastSucessMessage() {
	return (
		<Conatiner>
			<Icon>
				<img src={tick} alt="tick" />
			</Icon>
			<p>Successfully Confirmed</p>
		</Conatiner>
	);
}

export default ToastSucessMessage;
const Conatiner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Icon = styled.span`
	display: block;
	width: 30px;
	margin-right: 10px;
	img {
		display: block;
		width: 100%;
	}
`;
