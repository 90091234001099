import React from "react";
import styled from "styled-components";
import card1 from "../../../assets/images/card1.svg";
import card2 from "../../../assets/images/card2.svg";
import card3 from "../../../assets/images/card3.svg";
import card4 from "../../../assets/images/card4.svg";




function Card() {
	return  <Section id="cards">
        <div className="title">
            <h1 className="card-heading">Exclusive Supplier+ Rewards
             for the First <span className="yellow">30 Days</span> </h1>
        </div>
		<div className="services">
            <div className="service">
                <div className="icon">
                    <img src={card1} alt="card1" />
                </div>
                <h3>Free catalog visibility of ₹1200</h3>
                <p>Run advertisements for your catalogs to increase 
                    the visibility of your products and get more orders</p>
            </div>
            <div className="service active">
                <div className="icon">
                   <img src={card2} alt="card2" />
                </div>
                <h3>Dedicated Catalog Manager</h3>
                <p>Clear all your cataloging doubts like how to upload
                     catalogs, correct quality check errors and more</p>
            </div>
            <div className="service">
                <div className="icon">
                    <img src={card3} alt="card3" />
                </div>
                <h3>Free Return Shipping</h3>
                <p>Send out your orders stress-free with zero
                     fees on return shipping for the first month</p>
            </div>
            <div className="service">
                <div className="icon">
                    <img src={card4} alt="card4" />
                </div>
                <h3>No Order Cancellation Charges</h3>
                <p>Cancel orders that you can’t fulfil for unforeseen
                     reasons without worrying about penalties</p>
            </div>
         </div>   
            
    
	</Section>;
    
}

export default Card;


const Section = styled.section`
  background-color: white;
  padding: 4rem 4rem;
  margin-top: -13px;
   .card-heading{
        width: 50%;
        margin-bottom: 10%;
        max-width: 100%;
        color: black;
        font-style: normal;
        font-weight: 500;
        font-size: 45px;
        line-height: 68px;
        text-align: center;
        margin-left: 23%;
        margin-top: 40px;
      .yellow{
           color: #EAB600;
           font-style: normal;
           font-weight: 600;
           font-size: 45px;
           line-height: 68px;
        }
    }
    .services{
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 1rem;
      
    }
    .service{
        padding: 1.5rem;
        box-shadow: 0px 7px 29px 0px #fefeff33;
        transition: 0.3s ease-in-out;
        &:hover{
        background-color: #fff;
        transform: translateX(0.4rem) translateZ(1rem);
        box-shadow: 0px 7px 29px 0px rgba(100,100,111,0.2);
        border-radius: 10px;
    }
    }
    .active{
        background-color: #fff;
        box-shadow: 0px 7px 29px 0px 
        rgba(100,100,111,0.2);
        border-radius: 10px;
    }
    .icon{
        img{
            height: 3.9rem;
        }
    }
    h3{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }
    p{
        max-width:100%;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        color: #747474;
    }

    @media only screen and (max-width: 780px){
        .card-heading{
            width: 100%;
            max-width: 100%;
            color: black;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 40px;
            margin-left:-5%;
            text-align: left;
          .yellow{
                font-weight: 500;
                font-size: 30px;
                line-height: 30px;
            }
        }
        .services{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 3rem;
        }

    }
    @media only screen and (max-width: 560px){
        .services{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            gap: 3rem;
        }
    }
`;

