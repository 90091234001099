// EmailInput.jsx
import React from 'react';
import styled from 'styled-components';
import { TextField, InputAdornment, FormHelperText } from '@mui/material';
import { Email } from '@mui/icons-material';

const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    height: 50px;
    ${(props) => props.error && `
      border-color: red;
    `}
  }
`;

const EmailInput = ({ label, onChange, error, errorMessage, ...props }) => {
  return (
    <div>
      <StyledTextField
        variant="outlined"
        type="email"
        label={label || 'Email'}
        onChange={onChange}
        error={error}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Email />
            </InputAdornment>
          ),
        }}
      />
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};

export default EmailInput;
