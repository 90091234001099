import React from "react";
import styled from "styled-components";
import Admin from "../../../assets/images/admin.svg";
import Work1 from "../../../assets/images/work1.svg";
import Work2 from "../../../assets/images/work2.svg";
import Work3 from "../../../assets/images/work3.svg";
import Work4 from "../../../assets/images/work4.svg";
import Work5 from "../../../assets/images/work5.svg";
import Line from "../../../assets/images/line.svg";



function Working() {
	return  <Section>
        
            <div className="work-content">
                <div className="details">
                    <h1 className="heading">How It’s Work</h1>
                    <div className="line">
                    <img src={Line} alt="line" />
                    </div>
                    <div className="contents">
                       <div className="image">
                           <img src={Work1} alt="Work1" />
                       </div>
                       <div className="text">
                            <p className="yellow">Create Account</p>
                            <p className="txt">All you need is : GSTIN, Bank Account</p>
                        </div> 
                    </div>
                    <div className="contents">
                       <div className="image">
                           <img src={Work2} alt="Work1" />
                       </div>
                       <div className="text">
                            <p className="yellow">List Products</p>
                            <p className="txt">List the products you want to sell in your supplier panel</p>
                        </div> 
                    </div>
                    <div className="contents">
                       <div className="image">
                           <img src={Work3} alt="Work1" />
                       </div>
                       <div className="text">
                            <p className="yellow">Get Orders</p>
                            <p className="txt">Start getting orders from crores of
                             Indians actively shopping on our platform.</p>
                        </div> 
                    </div>
                    <div className="contents">
                       <div className="image">
                           <img src={Work4} alt="Work1" />
                       </div>
                       <div className="text">
                            <p className="yellow">Lowest Cost Shipping</p>
                            <p className="txt">Products are shipped to customers at lowest costs</p>
                        </div> 
                    </div>
                    <div className="contents">
                       <div className="image">
                           <img src={Work5} alt="Work1" />
                       </div>
                       <div className="text">
                            <p className="yellow">Receive Payments</p>
                            <p className="txt">Payments are deposited directly to your bank 
                            account following a 7-day payment cycle from order delivery.</p>
                        </div> 
                    </div>
                </div>
                <div className="board-image">
                    <img src={Admin} alt="Admin" />
                </div>
            </div>
    
	</Section>;
    
}

export default Working;


const Section = styled.div`
   padding: 3rem 0;
   background-color: white;
   
   .work-content{
       display: flex; 
       justify-content :space-between ;
       width: 92%;
       background: linear-gradient(105.79deg, #0A0A0A 0%, #59534F 99.51%, #263640 111.23%);
       border-radius: 10px;
       color: white;
       
       margin-left:5%;
      
    }
    .details{  
        margin-top: 7%;
    }
    .heading{
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 58px;
        color: #EEEEEE;
        margin-left: 14%;
        margin-bottom: 3%;
    }
    .line{
        position: absolute;
        left: 11.5%;
       img{
        width: 72%;
        margin-top: 13px;
        opacity: 0.5;
       }
    }
    .contents{
        display: flex;
        margin:5%;
        margin-left:13%;
       
    }
    .text{
       margin-left:30px; 
      
    }
    .txt{
        color: #D4D4D4;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
    }
    .yellow{
        color: #EAB600;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
    }
    .image{
        img{
            height: 2rem;
        }
    }
    .board-image{
        width: 80%;
        margin-top: 20px;
        img{
            width: 100%;   
        }
    }

    @media only screen and (max-width: 780px){
        .work-content{
            display: flex; 
            flex-direction: column;
        }
        .board-image{
            width: 100%;
            margin-top: 20px;
            img{
              width: 100%;   
            }
        }
        .line{
        position: absolute;
        left: 19%;
            img{
                width: 72%;
                margin-top: 17px;
                opacity: 0.5;
            }
        }
    }
    @media only screen and (max-width: 480px){
        .line{
        position: absolute;
        left: 20%;
            img{
                width: 72%;
                margin-top: 17px;
                opacity: 0.5;
            }
        }
    }
    @media only screen and (max-width: 380px){  
        .line{
        position: absolute;
        left: 22%;
            img{
                width: 82%;
                margin-top: 17px;
                opacity: 0.5;
            }
        }
    }


  
`;
