import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import SpotImage from "../../../assets/images/deliverybox.png";
import RightImage from "../../../assets/images/arrow-right.svg";


function SpotLight () {
	return  <Container>
          
          <Left>
              <Title className="title">Best & Fastest <span className="highlight"> Selling
                  <br />Online Platform</span></Title>
              <Description className="description">Donec a eros justo. Fusce egestas tristique ultrices. 
                  Nam tempor, <br />augue nec tincidunt molestie, massa nunc varius arcu, </Description>
            <StyleButton className="stylebutton">
                <Link to="#">
                    <MyButton className="btn">Start Selling <img src={RightImage} className="arrow" alt="Rightarrow" /></MyButton>
               </Link>
            </StyleButton>
             
             <Figures className="figure">
                <div className="number-box">
                    <div className="details">
                        <div className="box-one">
                            <small className="count">10+</small>
                            <p className="text">Lac, Trust Onerupee
                               <br /> to sell online</p>
                        </div>
                        <div className="box-two">
                            <small className="count">11+</small>
                            <p className="text">Customers buying  <br /> across India</p>
                        </div>
                        <div className="box-three">
                            <small className="count">2800+</small>
                            <p className="text">Pincode Supported <br />  for delivery</p>
                       </div>  
                       <div className="box-four">
                            <small className="count">700+</small>
                            <p className="text">Categories to sell  <br /> online</p>
                       </div>                     
                   </div>
                </div>
             </Figures>
            
                  
          </Left>
          <Right>
              <Image className="image" src={SpotImage} alt="SpotImage" />
          </Right>
    </Container>;
    
}

export default SpotLight;
const Container = styled.div`
  
   background-color: white;
  
   @media only screen and (max-width: 992px){
    .box-one{
      
      ::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 80px;
          background-color: #2b2929;
          transform: translateY(-57%);
       }
   }
   .box-two{
     
     ::after {
         position: absolute;
         content: "";
         width: 1px;
         height: 80px;
         background-color: #2b2929;
         left: 35%;
         top: 108%;
         transform: translateY(-57%);
       }   
   }
   .box-three{
     
     ::after {
        width: 1px;
        height: 67px;
        background-color: rgb(43, 41, 41);
        left: 48%;
        top: 125%;
       }
    }
     
    }
    @media only screen and (max-width: 780px){
    .title{
        width: 100%;
        margin-left: 0;
        text-align: center;
        font-size: 36px;
    }
    .description{
         width: 100%;
         margin-left: 0;
        text-align: center;
        font-size: 23px;
    }
    .stylebutton{
         margin-left:30%;
         text-align: center;
         margin-top: 5%;
    }
     .figure{
        display: flex;
        flex-wrap:wrap;
        margin-top: 100%;
        padding: 20px 2px;
        height: 50vh;
    }
     .details{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; 
    }
    .text{
        margin-bottom: 30px;
    }
    .box-one{
      
       ::after {
           position: absolute;
           content: "";
           width: 1px;
           height: 80px;
           background-color: #2b2929;
           transform: translateY(-57%);
        }
    }
    .box-two{
      
      ::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 80px;
          background-color: #2b2929;
          left: 35%;
          top: 108%;
          transform: translateY(-57%);
        }   
    }
    .box-three{
      
      ::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 80px;
          background-color: #2b2929;
          left: 51%;
          top: 108%;
          transform: translateY(-57%);
        }
    }
     .image{
            width: 75%;
            position: absolute;
            top: 82%;
            left: 14%;
        }
    }
    @media only screen and (max-width: 580px){
        .image{
            width: 77%;
            position: absolute;
            top: 79%;
           left: 14%;
        }
        .title{
        width: 100%;
        margin-left: 0;
        text-align: center;
        font-size: 30px;
      }
     .description{
         width: 80%;
         margin-left: 0;
        text-align: center;
        font-size: 20px;
     }
    
    }
    @media only screen and (max-width: 480px){
        .image{
            width: 77%;
            position: absolute;
            top: 81%;
           left: 14%;
        }
        .title{
        width: 100%;
        margin-left: 0;
        text-align: center;
        font-size: 30px;
      }
     .description{
         width: 70%;
         margin-left: 0;
        text-align: center;
        font-size: 20px;
     }
     
    .btn2{
        color: black;
        font-size: 13px;
    }
    }
`;

const Left = styled.div`
   display: flex;
   flex-direction: column;
  
   align-items: center;
   gap:20px;

`;
const Title = styled.h1`
   width: 60%;
   font-style: normal;
   font-weight: 700;
   font-size: 53px;
   color: black;
   letter-spacing: 2px;
   margin-left:-25%;
   margin-top: 5%;
   
    .highlight{
        color: #EAB600;
    }
`;
const Description = styled.p`
    width: 54%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: rgb(116, 116, 116);
    margin-left: -31%;
   
`;
const StyleButton = styled.button`
 background: linear-gradient(340.8deg, #FFDF17 18.2%, #FFF79E 167.34%);
  border: none;
  border-radius: 8px;
  padding: 10px 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: black;
  align-self: start;
  margin-left: 8%;
  margin-top: 2%;
`;
const MyButton = styled.div`
    border: none;
    background: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black;
   
    .arrow{
        position: relative;
        left: 10px;
        top: 10px;
       
    }
`;
const Figures = styled.div`
    width: 92%;
    height: 20vh;
    background-color: black;
    color: white;
    margin-top: 8%;
    border-radius: 5px;
    padding: 23px 2px;
    text-align: left;
    .details{
        display: flex;
        gap:7%;
        margin-left:3%;
    }
    .count{
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 44px;
        color: #EEEEEE;
    }
    .text{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #7c7c7c;
    }
    .box-one{
       
        ::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 80px;
            background-color: #2b2929;
            left: 20%;
            top: 108%;
            transform: translateY(-57%);
      }
      
    }
    .box-two{
       
       ::after {
           position: absolute;
           content: "";
           width: 1px;
           height: 80px;
           background-color: #2b2929;
           left: 35%;
           top: 108%;
           transform: translateY(-57%);
     }
     
   }
   .box-three{
       
       ::after {
           position: absolute;
           content: "";
           width: 1px;
           height: 80px;
           background-color: #2b2929;
           left: 51%;
           top: 108%;
           transform: translateY(-57%);
     }
     
   }
    
    
`;

const Right = styled.div`
    background-color: white;
    margin-left:-5%;
    margin-top:1%;
    
`;
const Image = styled.img`
    width: 36%;  
    position: absolute;
    top: 25%;
    left: 60%;
`;


