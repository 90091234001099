import React from "react";
import styled from "styled-components";

function VideoModal({ isOpen, onClose, videoId }) {
	if (!isOpen) {
		return null;
	}

	const videoUrl = videoId;

	return (
		<VideoModalWrapper onClick={onClose}>
			<VideoModalContent>
				<VideoPlayer src={videoUrl} allowFullScreen />
			</VideoModalContent>
		</VideoModalWrapper>
	);
}

export default VideoModal;
const VideoModalWrapper = styled.div`
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
`;

const VideoModalContent = styled.div`
	margin: auto;
	background-color: transparent;
	padding: 20px;
	width: 80%; /* or whatever width you want */
	padding-top: 46.25%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const VideoPlayer = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
