import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import Pagination from "../../general/Pagination";
import { stringFormatDate } from "../../utils/HelperFunctions";
import OrderActionsButton from "../Orders/OrderActionsButton";

function InventoryTable() {
	const [selectedOrder, setSelectedOrder] = useState({});
	const [isConfirmModal, setConfirmModal] = useState(false);
	const [isCancelModal, setCancelModal] = useState(false);
	const ordarData = [
		{
			id: 1,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 2,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 3,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 4,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 5,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 6,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 7,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
		{
			id: 8,
			product_code: "#678665G",
			image: "https://via.placeholder.com/150x150.png?text=Product+A",
			product_name: "Jhon Miller Shirt",
			stock: 234,
		},
	];
	const handleConfirmModal = () => {
		setConfirmModal(!isConfirmModal);
	};
	const handleCancelModal = () => {
		setCancelModal(!isCancelModal);
	};
	const handleConfirmClick = () => {
		console.log("sucess");

		setConfirmModal(false);
		toast(<ToastSucessMessage />);
	};
	return (
		<Container>
			<TableContainer>
				<thead>
					<TableRow>
						<TableHead className="slno">SI No.</TableHead>
						<TableHead>Product Code</TableHead>
						<TableHead>Image</TableHead>
						<TableHead>Product Name</TableHead>
						<TableHead>Stock</TableHead>

						<TableHead style={{ textAlign: "right" }}>
							Actions
						</TableHead>
					</TableRow>
				</thead>
				<TableBody>
					{ordarData.map((data) => (
						<TableRow>
							<TableCell className="slno">{data.id}</TableCell>
							<TableCell>{data.product_code}</TableCell>
							<TableCell>
								<Image src={data.image} alt="" />
							</TableCell>
							<TableCell>{data.product_name}</TableCell>
							<TableCell>{data.stock}</TableCell>
							<TableCell>
								<OrderActionsButton
									data={data}
									handleCancelModal={handleCancelModal}
									handleConfirmModal={handleConfirmModal}
									selectedOrder={selectedOrder}
									setSelectedOrder={setSelectedOrder}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</TableContainer>
			<Pagination />
		</Container>
	);
}

export default InventoryTable;
const Container = styled.div`
	overflow-x: scroll;
	background-color: ${COLORS.PRIMARY};
	padding: 10px;

	height: calc(100vh - 210px);
	width: 100%;
	padding-bottom: 60px;
`;
const TableContainer = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1150px;
`;
const TableBody = styled.tbody`
	transition: 0.3s;
	tr {
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			background-color: ${COLORS.SECONDARY};
		}
	}
`;

const TableHead = styled.th`
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.SECONDARY};
	.slno {
		/* max-width: 100px;
		display: flex;
		align-items: center;
		height: 100%; */
	}
`;

const TableCell = styled.td`
	text-align: left;
	vertical-align: middle;
	padding: 15px;
	font-size: 14px;
	max-width: 150px;
`;

const TableButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	overflow: visible;
	&:hover {
	}
`;

const Image = styled.img`
	width: 80px;
	height: 60px;
	object-fit: cover;
`;
