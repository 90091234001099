import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import * as Yup from "yup";
import { Link } from "react-router-dom";

function ShopDetails() {
	const schema = Yup.object({
		name: Yup.string().required("Shop Name is Required"),
		address_1: Yup.string().required("Adress Line 1 is Required"),
		address_2: Yup.string().required("Address Line 2 is Required"),
		city: Yup.string().required("City is Required"),
		state: Yup.string().required("State is Required"),
		pincode: Yup.number()
			.positive("Pincode nust be a positive number")
			.required("Pin Code is Required"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => console.log(data);

	return (
		<Container>
			<Title>Shop Details</Title>
			<FormSection onSubmit={handleSubmit(onSubmit)}>
				<InputWrapper>
					<InputLabel>Shop Name</InputLabel>
					<TextInput
						{...register("name")}
						type="text"
						name="name"
						placeholder="Enter Product Name"
					/>
					<Error>{errors.name?.message}</Error>
				</InputWrapper>
				<SectionBreaker>
					<SectionTitle>Shop&#160;Address</SectionTitle>
					<Line>
						<span></span>
					</Line>
				</SectionBreaker>
				<InputWrapper>
					<InputLabel>Address Line 1</InputLabel>
					<TextInput
						{...register("address_1")}
						type="text"
						name="address_1"
						placeholder=""
					/>
					<Error>{errors.address_1?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>Address Line 2</InputLabel>
					<TextInput
						{...register("address_2")}
						type="text"
						name="address_2"
						placeholder=""
					/>
					<Error>{errors.address_2?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>City</InputLabel>
					<TextInput
						{...register("city")}
						type="text"
						name="city"
						placeholder=""
					/>
					<Error>{errors.city?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>State</InputLabel>
					<TextInput
						{...register("state")}
						type="text"
						name="state"
						placeholder=""
					/>
					<Error>{errors.state?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>Pin code</InputLabel>
					<TextInput
						{...register("pincode")}
						type="number"
						name="pincode"
						placeholder=""
					/>
					<Error>{errors.pincode?.message}</Error>
				</InputWrapper>{" "}
				<ButtonContainer>
					<CancelButton>Cancel</CancelButton>
					<SubmitButton type="submit">Save</SubmitButton>
				</ButtonContainer>
			</FormSection>
		</Container>
	);
}

export default ShopDetails;
const Container = styled.div`
	background-color: #fff;
	padding: 20px;
	position: relative;
`;
const Title = styled.h3`
	font-size: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid ${COLORS.SECONDARY};
	font-family: dm_sans_reg;
`;
const FormSection = styled.form`
	display: block;
	margin-top: 30px;
`;
const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	position: relative;
`;

const InputLabel = styled.label`
	font-size: 16px;

	color: ${COLORS.SECONDARY_TEXT};
`;
const TextInput = styled.input`
	padding: 12px 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
`;
const Error = styled.p`
	font-size: 12px;
	color: red;
	bottom: -20px;
	right: 0;
	position: absolute;
`;
const SectionBreaker = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
`;
const SectionTitle = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 16px;
	margin-right: 20px;
`;
const Line = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	span {
		display: block;
		flex: 1;
		border-bottom: 1px solid #f0f0f0;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
`;
