import { useState, useCallback } from "react";
import axiosInstance from "../api";

const usePostApi = (url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const post = useCallback(
        async (body) => {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance.post(url, body);
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        },
        [url]
    );

    return { loading, data, error, post };
};

export default usePostApi;
