import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../constants";
import InventoryTable from "../includes/Inventory/InventoryTable";
import { updateActiveMenu } from "../store/reducers/AdminReducer";

function Inventory() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("inventory"));
	}, []);
	return (
		<Container>
			<Title>Inventory</Title>
			<FilterMenu>
				<Menu className="active">All Stocks</Menu>
				<Menu>Out of Stocks</Menu>
				<Menu>Low Stocks</Menu>
			</FilterMenu>
			<InventoryTable />
		</Container>
	);
}

export default Inventory;
const Container = styled.div`
	position: relative;
`;
const Title = styled.h2`
	font-size: 18px;
	margin-bottom: 10px;
`;
const FilterMenu = styled.div`
	background-color: ${COLORS.PRIMARY};
	padding: 10px 16px;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	margin-bottom: 10px;
	position: -webkit-sticky; /* Safari */
`;
const Menu = styled.span`
	padding: 6px 16px;
	display: block;
	border-radius: 3px;
	transition: 0.3s;
	font-size: 14px;
	text-transform: capitalize;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
	&.active {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
	&:hover {
		background-color: ${COLORS.THEME_COLOR};
		color: ${COLORS.PRIMARY_TEXT};
	}
`;
