import React from "react";
import styled from "styled-components";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import cancel from "../../../assets/images/cancel.svg";
import confirm from "../../../assets/images/order-confirmed.svg";
import shipped from "../../../assets/images/shipped.svg";

const OrderStatusButton = ({ status }) => {
	let fontColor;
	let bgColor;
	let icon;
	let label;

	switch (status) {
		case "not_confirmed":
			fontColor = "#ADADAD";
			bgColor = "#F5F5F5";
			label = "Not Confirmed";
			break;
		case "shipped":
			fontColor = "#5261C7";
			bgColor = "#e6f7ff";
			icon = shipped;
			label = "Shipped";
			break;
		case "canceled":
			fontColor = "#E02B1D";
			bgColor = "#FCEAE8";
			icon = cancel;
			label = "Canceled Order";
			break;
		case "confirm":
			fontColor = "#009262";
			bgColor = "#EAF8F2";
			icon = confirm;
			label = "Confirm Order";
			break;
		default:
			fontColor = "#2f363d";
			bgColor = "#fff";
			break;
	}

	return (
		<Button fontColor={fontColor} bgColor={bgColor}>
			{status != "not_confirmed" && (
				<Icon>
					<img src={icon} alt="icon" />
				</Icon>
			)}
			{label}
		</Button>
	);
};

const Button = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: none;
	border-radius: 5px;
	width: 100%;
	padding: 10px 10px;
	font-size: 14px;
	color: ${(props) => props.fontColor};
	background-color: ${(props) => props.bgColor};
	transition: background-color 0.3s;

	svg {
		margin-right: 5px;
		font-size: 20px;
	}

	/* &:hover {
		opacity: 0.8;
	} */
`;
const Icon = styled.span`
	min-width: 20px;
	width: 20px;
	display: block;
	margin-right: 6px;
	img {
		display: block;
		width: 100%;
	}
`;

export default OrderStatusButton;
