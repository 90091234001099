import { useState } from "react";
import styled from "styled-components";

const countries = [
	{
		name: "India",
		code: "+91",
		flag: "🇮🇳",
	},
	{
		name: "United States",
		code: "+1",
		flag: "🇺🇸",
	},
	{
		name: "United Kingdom",
		code: "+44",
		flag: "🇬🇧",
	},
	{
		name: "Australia",
		code: "+61",
		flag: "🇦🇺",
	},
	{
		name: "Canada",
		code: "+1",
		flag: "🇨🇦",
	},
];

const CountryModal = ({ handleCountryClick, selectedCountry }) => {
	const [searchValue, setSearchValue] = useState("");
	const filteredCountries = countries.filter((country) =>
		country.name.toLowerCase().includes(searchValue.toLowerCase())
	);

	return (
		<Container>
			<SearchInput
				placeholder="Search"
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
			/>

			{filteredCountries.map((country, index) => (
				<Country
					key={index}
					onClick={() => handleCountryClick(country)}
				>
					<Flag>{country.flag}</Flag>
					<Name>{country.name}</Name>
					<Code>({country.code})</Code>
				</Country>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	width: 300px;
	flex-direction: column;
	position: relative;
	z-index: 999;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 20px;
`;

const SearchInput = styled.input`
	padding: 10px;
	border-radius: 5px;
	border: none;
	outline: none;
	margin-bottom: 10px;
	border: 1px solid #9e9e9e;
`;

const Country = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 10px;

	&:hover {
		background-color: #f1f1f1;
	}
`;
const SelectedCountry = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 10px;

	&:hover {
		background-color: #f1f1f1;
	}
`;
const Flag = styled.span``;

const Name = styled.span`
	margin-left: 10px;
`;

const Code = styled.span`
	margin-left: auto;
`;

export default CountryModal;
