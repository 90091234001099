import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import { stringFormatDate } from "../../utils/HelperFunctions";
import CancelOrderModal from "./modals/CancelOrderModal";
import ConfirmOrderModal from "./modals/ConfirmOrderModal";
import OrderActionsButton from "./OrderActionsButton";
import { ToastContainer, toast } from "react-toastify";
import OrderStatusButton from "./OrderStatusButton";
import ToastSucessMessage from "../../general/Auth/ToastSucessMessage";

const OrderDetailsTable = () => {
	const [selectedOrder, setSelectedOrder] = useState({});
	const [isConfirmModal, setConfirmModal] = useState(false);
	const [isCancelModal, setCancelModal] = useState(false);

	const ordarData = [
		{
			id: 1,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "not_confirmed",
		},
		{
			id: 2,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "canceled",
		},
		{
			id: 3,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "shipped",
		},
		{
			id: 4,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "confirm",
		},
		{
			id: 5,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "canceled",
		},
		{
			id: 6,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "shipped",
		},
		{
			id: 7,
			order_id: "ORD-1234",
			customer_details: {
				name: "Aiswarya Haridas",
				phone: "+91 7012121235",
			},
			product_details: {
				title: "Truke BTG X1 Earbuds",
				description:
					"Game Mode, Quad Mic ENC, 20 RGB LEDs, 48H Playtime ",
			},
			shipping_adress: "123 Main St, Anytown USA",
			date: "2022-01-01",
			status: "canceled",
		},
	];

	const handleConfirmModal = () => {
		setConfirmModal(!isConfirmModal);
	};
	const handleCancelModal = () => {
		setCancelModal(!isCancelModal);
	};
	const handleConfirmClick = () => {
		console.log("sucess");

		setConfirmModal(false);
		toast(<ToastSucessMessage />);
	};
	return (
		<TableWrapper>
			<ToastContainer
				position="bottom-center"
				autoClose={3000}
				limit={1}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<ConfirmOrderModal
				selectedOrder={selectedOrder}
				handleModal={handleConfirmModal}
				isModal={isConfirmModal}
				handleConfirmClick={handleConfirmClick}
			/>
			<CancelOrderModal
				selectedOrder={selectedOrder}
				handleModal={handleCancelModal}
				isModal={isCancelModal}
			/>
			<TableContainer>
				<thead>
					<TableRow>
						<TableHead>SI No.</TableHead>
						<TableHead>Order ID</TableHead>
						<TableHead>Product Details</TableHead>
						<TableHead>Customer Details</TableHead>
						<TableHead>Shipping Address</TableHead>
						<TableHead>Date</TableHead>
						<TableHead>Status</TableHead>
						<TableHead>Actions</TableHead>
					</TableRow>
				</thead>
				<TableBody>
					{ordarData.map((data) => (
						<TableRow>
							<TableCell>{data.id}</TableCell>
							<TableCell>{data.order_id}</TableCell>
							<TableCell>
								<ProductTitle>
									{data.product_details.title}
								</ProductTitle>
								<ProductDescription>
									{data.product_details.description}
								</ProductDescription>
							</TableCell>
							<TableCell>
								<CustomerName>
									{data.customer_details.name}
								</CustomerName>
								<CustomerPhone>
									{" "}
									{data.customer_details.phone}
								</CustomerPhone>
							</TableCell>
							<TableCell>{data.shipping_adress}</TableCell>
							<TableCell>{stringFormatDate(data.date)}</TableCell>
							<TableCell>
								<OrderStatusButton status={data.status} />
							</TableCell>
							<TableCell>
								<TableButton>
									<OrderActionsButton
										handleCancelModal={handleCancelModal}
										handleConfirmModal={handleConfirmModal}
										data={data}
										selectedOrder={selectedOrder}
										setSelectedOrder={setSelectedOrder}
									/>
								</TableButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</TableContainer>
		</TableWrapper>
	);
};

export default OrderDetailsTable;
const TableWrapper = styled.div`
	overflow-x: scroll;
	background-color: ${COLORS.PRIMARY};
	padding: 10px;

	height: calc(100vh - 328px);
	width: 100%;
	padding-bottom: 60px;
`;

const TableContainer = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1150px;
`;
const TableBody = styled.tbody`
	transition: 0.3s;
	tr {
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			background-color: ${COLORS.SECONDARY};
		}
	}
`;

const TableHead = styled.th`
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.SECONDARY};
`;

const TableCell = styled.td`
	text-align: left;
	vertical-align: middle;
	padding: 15px;
	font-size: 14px;
	max-width: 250px;
`;

const TableButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	overflow: visible;
	&:hover {
	}
`;

const ProductTitle = styled.p`
	font-size: 14px;
`;
const ProductDescription = styled.p`
	font-size: 13px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const CustomerName = styled.p`
	font-size: 14px;
`;
const CustomerPhone = styled.p`
	font-size: 13px;
	color: ${COLORS.SECONDARY_TEXT};
`;
