import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../store/reducers/UserReducer";
import { useNavigate } from "react-router-dom";

//capitalize function: This function capitalizes the first letter of a string.
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

//truncate function: This function truncates a string to a given length and adds ellipsis at the end if necessary.
export const truncate = (str, maxLength = 100) => {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + "...";
    }

    return str;
};

//isEmpty function: This function checks whether an object or array is empty or not.
export const isEmpty = (obj) => {
    return (
        obj === null ||
        obj === undefined ||
        (typeof obj === "string" && obj.trim().length === 0) ||
        (Array.isArray(obj) && obj.length === 0) ||
        (typeof obj === "object" && Object.keys(obj).length === 0)
    );
};

//formatDate function: This function formats a date object to a string in a specified format.
export const formatDate = (date, format = "YYYY-MM-DD") => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return format.replace("DD", day).replace("MM", month).replace("YYYY", year);
};

///Here is a function in JavaScript that can format a date string into MMM DD, YYYY format
export function stringFormatDate(dateString) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
}

// debounce function: This function debounces a given function and delays its execution for a given number of milliseconds.
export const debounce = (func, delay = 300) => {
    let timerId;

    return (...args) => {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

//groupBy() function: This function can be used to group an array of objects by a specific property.
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

//flatten() function: This function can be used to flatten a nested array.
function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(
            Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
        );
    }, []);
}

//formatCurrency() function: This function can be used to format a number as currency.
function formatCurrency(num, currency = "USD") {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
    }).format(num);
}

//isAuthenticated helper function in JavaScript for React that checks if a user is authenticated:
export const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    const { isVerified } = useSelector((state) => state.user.userData);
    return isVerified;
};

export const getToken = () => {
    //   const { token } = useSelector((state) => state.user.userData);
    const localData = JSON.parse(localStorage.getItem("userData"));
    const token = localData ? localData.token : null;
    //   return localStorage.getItem("token");
    return token;
};

export const logOut = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    dispatch(
        updateUserData({
            isVerified: false,
            token: "",
        })
    );
    navigation("/");
};
