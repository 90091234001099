import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Order from "../pages/Order";
import { updateActiveMenu } from "../store/reducers/AdminReducer";

function OrdersRouter() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("orders"));
	}, []);
	return (
		<Container>
			<Routes>
				<Route exact path="/" element={<Order />} />
			</Routes>
		</Container>
	);
}

export default OrdersRouter;
const Container = styled.div`
	height: 100%;
	overflow-y: scroll;
`;
