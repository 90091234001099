import React from "react";
import styled from "styled-components";
import box from "../../../assets/images/package.png";
import { COLORS } from "../../../constants";

function Banner() {
	return (
		<Container>
			<LeftSection>
				<Label>Hi, Autumn Phillips</Label>
				<Greeting>Good Morning, Ready to Ship Your Orders</Greeting>
			</LeftSection>
			<RightSection>
				<img src={box} alt="Package Image" />
			</RightSection>
		</Container>
	);
}

export default Banner;
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px;
	background-color: rgba(255, 255, 240, 1);
`;
const LeftSection = styled.div``;
const RightSection = styled.div`
	img {
		height: 100%;
		display: block;
		max-height: 180px;
		margin: -28px 0;
	}
`;
const Label = styled.p`
	font-size: 16px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const Greeting = styled.h4`
	font-size: 22px;
	color: ${COLORS.PRIMARY_TEXT};
	margin-top: 10px;
	font-family: "dm_sans_reg";
	max-width: 300px;
`;
