// PasswordInput.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    height: 50px;
    ${(props) => props.error && `
      border-color: red;
    `}
  }
`;

const PasswordInput = ({ label, onChange, error, errorMessage, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <StyledTextField
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        label={label || 'Password'}
        onChange={onChange}
        error={error}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};

export default PasswordInput;
