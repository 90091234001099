import React, { useState } from "react";
import styled from "styled-components";
import AddProductDetails from "../../includes/Products/AddProductDetails";
import SelectCategory from "../../includes/Products/SelectCategory";

function AddProduct() {
	const [currentPage, setCurrentPage] = useState("add-category");
	return (
		<Container>
			{currentPage === "add-category" ? (
				<CategorySelectorCover>
					<SelectCategory setCurrentPage={setCurrentPage} />
				</CategorySelectorCover>
			) : currentPage === "add-product" ? (
				<ProductSelectorCover>
					<AddProductDetails setCurrentPage={setCurrentPage} />
				</ProductSelectorCover>
			) : null}
		</Container>
	);
}

export default AddProduct;
const Container = styled.div`
	&::-webkit-scrollbar {
		opacity: 0;
	}
`;
const CategorySelectorCover = styled.div``;
const ProductSelectorCover = styled.div`
	&::-webkit-scrollbar {
		display: none !important;
	}
`;
