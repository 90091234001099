import { Alert, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../constants";
import flag from "../../../assets/images/flag.png";
import bg from "../../../assets/images/sign_bg.png";
import SIgnInRightSection from "./SIgnInRightSection";
import CountryModal from "../CountryModal";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../store/reducers/UserReducer";
import PasswordInput from "../../reusables/PasswordInput";
import EmailInput from "../../reusables/EmailInput";
import usePostApi from "../../../hooks/usePostApi";
import { ToastContainer, toast } from "react-toastify";

function EnterPhone() {
    const userDetails = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    console.log("====================================");
    console.log(userDetails, "userdata");
    console.log("====================================");
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isCountryModal, setCountryModal] = useState(false);
    const [isError, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong!");

    const { loading, data, error, post } = usePostApi(
        API_BASE_URL + "users/admin/login/"
    );
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });
    const [selectedCountry, setSelectedCountry] = useState({
        name: "India",
        code: "+91",
        flag: "🇮🇳",
    });
    const validateLoginData = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!loginData.email || !loginData.password) {
            if (!loginData.email) {
                setEmailError(true);
            }
            if (!loginData.password) {
                setPasswordError(true);
            }
            setError(true);
            setErrorMessage("Please fill in both email and password.");
            return false;
        } else if (!emailRegex.test(loginData.email)) {
            setError(true);
            setEmailError(true);
            setErrorMessage("Invalid email format.");
            return false;
        } else {
            setError(false);
            return true;
        }
    };

    const onSubmit = async () => {
        const formValid = validateLoginData();
        if (formValid) {
            post(loginData);
        }
    };
    useEffect(() => {
        if (data) {
            if (data.StatusCode === 6000) {
                dispatch(
                    updateUserData({
                        email: data.data.email,
                        token: data.data.response.access,
                        isVerified: true,
                    })
                );
                navigate("/dashboard");
            } else if (data.StatusCode === 6001) {
                setErrorMessage(data.data.message);
                toast.error(data.data.message);
            }
        }
    }, [data, dispatch, navigate]);
    return (
        <>
            <Container>
                <LeftSection>
                    {" "}
                    <Cover>
                        <Title>Hello Again</Title>
                        <Description>
                            Welcome back, you’ve been missed
                        </Description>
                        <InputContainer>
                            <InputDiv>
                                <EmailInput
                                    label="Enter your email"
                                    error={emailError}
                                    errorMessage={errorMessage}
                                    onChange={(e) => {
                                        setEmailError(false);
                                        setLoginData((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }));
                                    }}
                                />
                            </InputDiv>
                            <InputDiv>
                                <PasswordInput
                                    label="Enter your password"
                                    error={passwordError}
                                    errorMessage={"Enter Valid password"}
                                    onChange={(e) => {
                                        setPasswordError(false);
                                        setLoginData((prev) => ({
                                            ...prev,
                                            password: e.target.value,
                                        }));
                                    }}
                                />
                            </InputDiv>
                        </InputContainer>
                        <SendOTPButton onClick={onSubmit} disabled={loading}>
                            {loading ? "Loading..." : "Login"}
                        </SendOTPButton>
                        <AgreementLabel>
                            By continuing, you agree to onerupee’s
                            <br />
                            <PolicyLink>Terms & Conditions</PolicyLink> &{" "}
                            <PolicyLink>Privacy Policy</PolicyLink>
                        </AgreementLabel>
                    </Cover>
                </LeftSection>
                <RightSection>
                    <SIgnInRightSection />
                </RightSection>
            </Container>
            {isError && <Alert severity="error">{errorMessage}</Alert>}
            <ToastContainer />
        </>
    );
}

export default EnterPhone;
const Container = styled.div`
    display: grid;
    grid-template-columns: 6fr 5fr;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    background-color: #fffcf7;
    position: relative;
    padding-top: 20%;
`;
const Cover = styled.div`
    max-width: 400px;
    width: 100%;
`;
const Title = styled.h1`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: ${COLORS.PRIMARY_TEXT};
`;

const Description = styled.p`
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: ${COLORS.SECONDARY_TEXT};
    margin-bottom: 80px;
`;

const InputContainer = styled.div`
    /* display: flex; */
    align-items: center;
    margin-bottom: 70px;
    position: relative;
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 100%;
    }
    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
        border-color: #eab600 !important;
    }

    .Mui-focused,
    .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
        color: #eab600 !important;
        border-color: #eab600;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
        border-color: #eab600;
    }
`;

const CountrySelect = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50%;
    position: relative;
    margin-right: 20px;
    transform: translateY(9px);
    cursor: pointer;
    img {
        width: 100%;
        display: block;
        border-radius: 50%;
        overflow: hidden;
    }
    /* :hover {
		opacity: 0.8;
	} */
`;

const CountryFlag = styled.span`
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    img {
        width: 100%;
        display: block;
    }
`;
const CountaryCode = styled.p`
    color: ${COLORS.SECONDARY_TEXT};
`;
const SendOTPButton = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    color: ${COLORS.PRIMARY_TEXT};
    background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        /* transform: translateY(-3px); */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }
`;

const AgreementLabel = styled.label`
    font-size: 14px;
    color: grey;
    margin-top: 30px;
    position: absolute;
    bottom: 50px;
    max-width: 300px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
`;

const PolicyLink = styled.a`
    color: #eab600;
    text-decoration: underline;
`;

const RightSection = styled.div``;
const CountrySection = styled.div`
    position: absolute;
    background-color: #fff;
    top: 50px;
`;
const Error = styled.p`
    position: absolute;
    font-size: 13px;
    color: red;
    bottom: -20px;
    right: 0px;
`;
const InputDiv = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;
const Label = styled.label``;
// const EmailInput = styled.input`
// 	width: 100%;
// 	height: 50px;
// 	padding:0px 15px;
// 	border-radius:8px;
// `;
