import React from "react";
import styled from "styled-components";
import CountCard from "../Orders/CountCard";
import time from "../../../assets/images/user.svg";
import cancel from "../../../assets/images/pending_vendor.svg";
import returnIcon from "../../../assets/images/adminPending.svg";
import money from "../../../assets/images/earnings.svg";
import commission from "../../../assets/images/commision.svg";

function OrderUpdates({ datas }) {
    return (
        <Container>
            <Title>Order Updates</Title>
            <UpdateContainer>
                <CountCard
                    icon={time}
                    label="Total Vendors"
                    count={datas?.vendors}
                />
                <CountCard
                    icon={cancel}
                    label="Vendor Pending Orders"
                    count={datas?.vendor_pending_orders}
                />
                <CountCard
                    icon={returnIcon}
                    label="Admin Pending Orders"
                    count={datas?.admin_pending_orders}
                />
                <CountCard
                    icon={money}
                    label="Payment Pending Request"
                    count={datas?.payment_pending_request}
                />
                <CountCard
                    icon={commission}
                    label="Commision"
                    count={`₹${datas?.commision}`}
                />
            </UpdateContainer>
        </Container>
    );
}

export default OrderUpdates;
const Container = styled.div`
    margin-top: 30px;
`;
const Title = styled.h3`
    font-size: 18px;
    font-family: "dm_sans_reg";
`;
const UpdateContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
    @media all and (max-width: 980px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
