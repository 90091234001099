import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import search from "../../../assets/images/Search.svg";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import Pagination from "../../general/Pagination";

function TranstionList() {
	const ordarData = [
		{
			id: 1,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 2,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 3,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 4,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 5,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 6,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 7,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
		{
			id: 8,
			transaction_id: "#847847",
			amount: 36473,
			payment_method: "Phone pay",
			status: "Success",
			date: "20 Jan 2023",
		},
	];
	return (
		<Container>
			<TopSection>
				<Title>Transactions</Title>
				<RightSection>
					<SearchContainer>
						<SearchIcon src={search} />
						<Input placeholder="Search" />
					</SearchContainer>
					<DownloadButton>
						<DownArrow />
						Download
					</DownloadButton>
				</RightSection>
			</TopSection>
			<ListContainer>
				<TableContainer>
					<thead>
						<TableRow>
							<TableHead>Transaction ID</TableHead>
							<TableHead>Amount</TableHead>
							<TableHead>Payment Method</TableHead>
							<TableHead>Status</TableHead>
							<TableHead>Date</TableHead>
						</TableRow>
					</thead>
					<TableBody>
						{ordarData.map((data) => (
							<TableRow>
								<TableCell>{data.transaction_id}</TableCell>
								<TableCell>{data.amount}</TableCell>
								<TableCell>{data.payment_method}</TableCell>
								<TableCell>{data.status}</TableCell>
								<TableCell>{data.date}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</TableContainer>
			</ListContainer>
			<Pagination />
		</Container>
	);
}

export default TranstionList;
const Container = styled.div`
	margin-top: 20px;
	position: relative;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Title = styled.h3`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
	margin-bottom: 10px;
	font-family: "dm_sans_reg";
`;
const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
`;
const SearchContainer = styled.span`
	display: block;
	background-color: #fff;
	padding: 12px 20px;
	display: flex;
	justify-content: flex-start;
	align-content: center;
	border-radius: 4px;
	svg {
		color: ${COLORS.SECONDARY_TEXT};
	}
`;
const SearchIcon = styled.img`
	width: 15px;
	display: block;
	margin-right: 10px;
`;
const Input = styled.input`
	border: none;
	outline: none;
	width: 200px;
	font-size: 15px;
`;
const DownloadButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	:hover {
		opacity: 0.8;
	}
`;
const DownArrow = styled(ArrowDownwardOutlinedIcon)`
	font-size: 14px;
	margin-right: 6px;
`;
const ListContainer = styled.div`
	padding: 10px 20px 20px;
	background-color: #fff;
	margin-top: 16px;
	border-radius: 5px;
	height: calc(100vh - 343px);
	overflow-y: scroll;
	border-top: 20px solid #fff;
	padding-bottom: 80px;
`;
const TableContainer = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1150px;
`;
const TableBody = styled.tbody`
	transition: 0.3s;
	tr {
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			background-color: ${COLORS.SECONDARY};
		}
	}
`;

const TableHead = styled.th`
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
	color: ${COLORS.SECONDARY_TEXT};
`;

const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.SECONDARY};
	.slno {
		/* max-width: 100px;
		display: flex;
		align-items: center;
		height: 100%; */
	}
`;

const TableCell = styled.td`
	text-align: left;
	vertical-align: middle;
	padding: 25px 15px;
	font-size: 14px;
	/* max-width: 150px; */
`;

const TableButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	overflow: visible;
	&:hover {
	}
`;

const Image = styled.img`
	width: 80px;
	height: 60px;
	object-fit: cover;
`;
