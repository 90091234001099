import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";

function CoundCard({ label, count, icon }) {
    return (
        <Container>
            <LeftSection>
                <Label>{label}</Label>
                <Count>{count}</Count>
            </LeftSection>
            <RightSection>
                <IconCover>
                    <Icon src={icon} alt="icon" />
                </IconCover>
            </RightSection>
        </Container>
    );
}

export default CoundCard;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLORS.PRIMARY};
    padding: 16px;
    /* box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
		rgba(255, 255, 255, 0.25) 0px 1px 0px inset; */
    border-radius: 5px;
    height: 100%;
`;
const LeftSection = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;
const Label = styled.p`
    font-size: 15px;
    text-transform: capitalize;
    color: ${COLORS.SECONDARY_TEXT};
    margin-bottom: 10px;
`;
const Count = styled.p`
    font-size: 24px;
    font-family: dm_sans_med;
`;
const RightSection = styled.span`
    display: block;
`;
const IconCover = styled.span`
    display: block;
    width: 55px;
    /* height: 90px;
    padding: 18px; */
    /* background-color: ${COLORS.SECONDARY}; */
    border-radius: 50%;
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;
