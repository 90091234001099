import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import defaultUserImage from "../../assets/images/user.jpg";
import notification from "../../assets/images/notification.svg";
import message from "../../assets/images/sms.svg";
import { API_BASE_URL, COLORS } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import useGetApi from "../../hooks/useGetApi";
import { Button, ButtonGroup } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../store/reducers/UserReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { name, image } = useSelector((state) => state.user.userData);

	const { data, loading } = useGetApi(API_BASE_URL + "users/admin-data/");
	const [tooltip, setToolTip] = useState(false);

	const handleToolTip = () => setToolTip(!tooltip);

	const handleLogout = () => {
		dispatch(
			updateUserData({
				email: "",
				isVerified: false,
				token: "",
			})
		);
		navigate("/");
	};

	useEffect(() => {
		if (data?.data?.admin) {
			dispatch(
				updateUserData({
					name: data.data.admin.title,
					image: data.data.admin.image || defaultUserImage,
				})
			);
		}
	}, [data, dispatch]);

	const buttons = [
		<Button
			key="edit"
			sx={{
				width: "100%",
				backgroundColor: "transparent",
				color: "rgb(153, 153, 153)",
				fontSize: "16px",
				textAlign: "left",
				display: "flex",
				alignItems: "center",
				justifyContent: "left",
				gap: "12px",
				textTransform: "capitalize",
				padding: "8px 16px",
				borderRadius: "8px",
				"&:hover": {
					backgroundColor: "transparent",
				},
			}}
			onClick={() => navigate("/edit-profile")}
		>
			<FontAwesomeIcon icon={faUser} fontSize="25" color="#ffb240" />
			Edit Profile
		</Button>,
		<Button
			key="logout"
			sx={{
				width: "100%",
				backgroundColor: "transparent",
				color: "rgb(153, 153, 153)",
				fontSize: "16px",
				textAlign: "left",
				display: "flex",
				justifyContent: "left",
				alignItems: "center",
				gap: "12px",
				textTransform: "capitalize",
				padding: "8px 16px",
				borderRadius: "8px",
				"&:hover": {
					backgroundColor: "transparent",
				},
			}}
			onClick={handleLogout}
		>
			<LogoutIcon sx={{ fontSize: "25", color: "#e02b1d" }} />
			Logout
		</Button>,
	];

	return (
		<HeaderContainer>
			<LeftSection to="/">
				<Logo src={logo} alt="company logo" />
			</LeftSection>
			<RightSection>
				<NotificationIcon>
					<ActiveNotification></ActiveNotification>
					<Notification src={notification} alt="notification icon" />
				</NotificationIcon>
				<MessageIcon>
					<ActiveNotification></ActiveNotification>
					<Notification src={message} alt="message icon" />
				</MessageIcon>
				<UserSection onClick={handleToolTip}>
					<UserInfo>
						<UserAvatar src={image || defaultUserImage} alt="user avatar" />
						<UserDetails>
							<UserName>{loading ? "Loading..." : name}</UserName>
							<UserType>Admin</UserType>
						</UserDetails>
						{tooltip && (
							<TooltipContainer>
								<ButtonGroup
									orientation="vertical"
									aria-label="Vertical button group"
									variant="contained"
									sx={{
										width: "100%",
										backgroundColor: "#fff",
										boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
										borderRadius: "8px",
										padding: "16px",
									}}
								>
									{buttons}
								</ButtonGroup>
							</TooltipContainer>
						)}
					</UserInfo>
				</UserSection>
			</RightSection>
		</HeaderContainer>
	);
};

export default Header;

const HeaderContainer = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	padding: 0 30px;
	background-color: ${COLORS.PRIMARY};
	box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
`;

const LeftSection = styled(Link)`
	display: block;
	flex: 1;
`;

const Logo = styled.img`
	display: block;
	width: 120px;

	@media (max-width: 768px) {
		width: 100px;
	}
`;

const RightSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const NotificationIcon = styled.div`
	margin-right: 20px;
	color: rgb(153, 153, 153);
	width: 22px;
	position: relative;
	cursor: pointer;

	@media (max-width: 768px) {
		margin-right: 0;
		margin-bottom: 12px;
	}
`;
const Notification = styled.img`
	display: block;
	width: 100%;
`;
const ActiveNotification = styled.span`
	width: 12px;
	height: 12px;
	display: block;
	border-radius: 50%;
	background-color: #fc0206;
	border: 1px solid #fff;
	position: absolute;
	top: 0;
	left: 0;
`;
const MessageIcon = styled.div`
	margin-right: 20px;
	color: #999;
	width: 22px;
	position: relative;
	cursor: pointer;

	@media (max-width: 768px) {
		margin-right: 0;
		margin-bottom: 12px;
	}
`;
const UserSection = styled.div`
	display: flex;
	align-items: center;
	border-left: 1px solid #000;
	padding-left: 20px;
	cursor: pointer;
	position: relative;
`;

const UserInfo = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const UserName = styled.span`
	font-weight: medium;
	font-size: 14px;
	margin-right: 8px;
	color: ${COLORS.PRIMARY_TEXT};
`;
const UserDetails = styled.span`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;
const UserType = styled.span`
	font-size: 13px;
	color: ${COLORS.SECONDARY_TEXT};
`;

const UserAvatar = styled.img`
	height: 35px;
	border-radius: 50%;

	@media (max-width: 768px) {
		height: 30px;
	}
`;
const TooltipContainer = styled.div`
	position: absolute;
	width: 237px;
	background: rgb(255, 255, 255);
	top: 45px;
	right: -6px;
	transition: 0.4s;
	height: 100px;
`;
