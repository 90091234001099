import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";
import { isAuthenticated } from "../utils/HelperFunctions";
import PageNotFound from "../general/PageNotFound";
import LandingPage from "../pages/LandingPage/LandingPage";

const MainRouter = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/*"
                    element={isAuthenticated() ? <AppRouter /> : <AuthRouter />}
                />
                <Route exact path="/home" element={<LandingPage />} />
                <Route
                    path="/app/*"
                    element={isAuthenticated() ? <AppRouter /> : <AuthRouter />}
                />
                <Route
                    path="*"
                    element={isAuthenticated() ? <AppRouter /> : <AuthRouter />}
                />
            </Routes>
        </>
    );
};

export default MainRouter;
