import React, { useState } from "react";
import styled from "styled-components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { COLORS } from "../../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";

function InputSection() {
	const [productDetails, setProductDetails] = useState({});

	const schema = Yup.object({
		name: Yup.string().required("Product Name is Required"),
		description: Yup.string(),
		brand: Yup.string(),
		actual_price: Yup.number("Select a vaild price")
			.required("Select a vaild price")
			.positive(),
		attribute: Yup.string(),
		attribute_value: Yup.string(),
		display_name: Yup.string().required("Display Name is Required"),
		display_price: Yup.number("Select a vaild price")
			.required("Select a vaild price")
			.positive("Select a vaild price"),
		genaral: Yup.string().required(""),
		gst: Yup.string(),
		height: Yup.number().required().positive(),
		hsn_code: Yup.string().required(),
		length: Yup.number().required().positive(),

		product_code: Yup.string().required(),
		return: Yup.string().required(),
		stock: Yup.number().required().positive().integer(),
		warranty: Yup.string().required(),
		weight: Yup.number().required().positive(),
		width: Yup.number().required().positive(),
	}).required();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => console.log(data);

	return (
		<>
			<Container>
				<CopyInputSection>
					<RadioButton>
						<Tick />
					</RadioButton>
					<Content>
						<ContentLabel>Copy input to all details</ContentLabel>
						<ContantDescription>
							if you want to change spesfic field for perticular
							products like color, fabric, etc. you can change it
							by selecting that product
						</ContantDescription>
					</Content>
				</CopyInputSection>

				<FormSection onSubmit={handleSubmit(onSubmit)}>
					<BasicInput>
						<InputWrapper>
							<InputLabel>Product Name</InputLabel>
							<TextInput
								{...register("name")}
								type="text"
								name="name"
								placeholder="Enter Product Name"
							/>
							<Error>{errors.name?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Product Description</InputLabel>
							<TextArea
								{...register("description")}
								name="description"
								placeholder="Enter Product Description"
							/>
							<Error>{errors.description?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Brand</InputLabel>
							<BrandSelect name="brand" {...register("brand")}>
								<option value="">-- Select a Brand --</option>
								<option value="nike">Nike</option>
								<option value="adidas">Adidas</option>
								<option value="puma">Puma</option>
							</BrandSelect>
							<Error>{errors.brand?.message}</Error>
						</InputWrapper>
					</BasicInput>
					<SectionBreaker>
						<SectionTitle>General</SectionTitle>
						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<GeneralInput>
						<InputWrapper>
							<InputLabel>Genaral</InputLabel>
							<BrandSelect
								name="genaral"
								{...register("genaral")}
							>
								<option value="">-- Select a Genaral --</option>
								<option value="nike">Nike</option>
								<option value="adidas">Adidas</option>
								<option value="puma">Puma</option>
							</BrandSelect>
							<Error>{errors.genaral?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Product Code</InputLabel>
							<TextInput
								{...register("product_code")}
								type="text"
								name="product_code"
								placeholder="Enter Product Code"
							/>
							<Error>{errors.product_code?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>HSN Code</InputLabel>
							<TextInput
								{...register("hsn_code")}
								type="text"
								name="hsn_code"
								placeholder="Enter Product Code"
							/>
							<Error>{errors.hsn_code?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Return In</InputLabel>
							<BrandSelect name="return" {...register("return")}>
								<option value="">-- Select a Return--</option>
								<option value="7">7 Days</option>
								<option value="14">14 Days</option>
								<option value="30">30 Days</option>
							</BrandSelect>
							<Error>{errors.return?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Warranty</InputLabel>
							<BrandSelect
								name="warranty"
								{...register("warranty")}
							>
								<option value="">-- Select Warranty--</option>
								<option value="0">None</option>
								<option value="6 months">6 Months</option>
								<option value="1 year">1 Year</option>
							</BrandSelect>
							<Error>{errors.warranty?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Actual Price</InputLabel>
							<TextInput
								{...register("actual_price")}
								type="number"
								name="actual_price"
								step=".01"
								placeholder="₹0000"
							/>
							<Error>{errors.actual_price?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Display Price</InputLabel>
							<TextInput
								{...register("display_price")}
								type="number"
								name="display_price"
								step=".01"
								placeholder="₹0000"
							/>
							<Error>{errors.display_price?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>GST</InputLabel>
							<TextInput
								{...register("gst")}
								type="text"
								name="gst"
								placeholder="Enter GST amount"
							/>
							<Error>{errors.gst?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Stock</InputLabel>
							<TextInput
								{...register("stock")}
								type="number"
								name="stock"
								placeholder="Enter stock limit"
							/>
							<Error>{errors.stock?.message}</Error>
						</InputWrapper>{" "}
					</GeneralInput>
					<SectionBreaker>
						<SectionTitle>Shipping</SectionTitle>
						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<ShippingInput>
						<InputWrapper>
							<InputLabel>Weight</InputLabel>
							<TextInput
								{...register("weight")}
								type="number"
								name="weight"
								step=".01"
								placeholder="00"
							/>
							<Error>{errors.weight?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Length (cm)</InputLabel>
							<TextInput
								{...register("length")}
								type="number"
								name="length"
								step=".01"
								placeholder="00"
							/>
							<Error>{errors.length?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Width (cm)</InputLabel>
							<TextInput
								{...register("width")}
								type="number"
								name="width"
								placeholder="00"
								step=".01"
							/>
							<Error>{errors.width?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Height (cm)</InputLabel>
							<TextInput
								{...register("height")}
								type="number"
								name="height"
								placeholder="00"
								step=".01"
							/>
							<Error>{errors.height?.message}</Error>
						</InputWrapper>{" "}
					</ShippingInput>
					<SectionBreaker>
						<SectionTitle>Attributes</SectionTitle>
						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<AttributeInput>
						<InputWrapper>
							<InputLabel>Display Name</InputLabel>
							<TextInput
								{...register("display_name")}
								type="text"
								name="display_name"
								placeholder="Enter Name"
							/>
							<Error>{errors.display_name?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Values</InputLabel>
							<BrandSelect
								name="attribute"
								{...register("attribute")}
							>
								<option value="">
									-- Select a Attribute --
								</option>
								<option value="nike">Nike</option>
								<option value="adidas">Adidas</option>
								<option value="puma">Puma</option>
							</BrandSelect>{" "}
							<Error>{errors.attribute?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Values</InputLabel>
							<BrandSelect
								name="attribute_value"
								{...register("attribute_value")}
							>
								<option value="">-- Select a Value --</option>
								<option value="nike">Nike</option>
								<option value="adidas">Adidas</option>
								<option value="puma">Puma</option>
							</BrandSelect>{" "}
							<Error>{errors.attribute_value?.message}</Error>
						</InputWrapper>
					</AttributeInput>
					<ButtonContainer>
						<CancelButton>Cancel</CancelButton>
						<SubmitButton type="submit">Submit</SubmitButton>
					</ButtonContainer>
				</FormSection>
			</Container>
		</>
	);
}

export default InputSection;
const Container = styled.div`
	margin-top: 20px;
`;
const CopyInputSection = styled.div`
	padding: 16px;
	border: 1px solid ${COLORS.THEME_COLOR};
	background-color: #fffff0;
	border-radius: 6px;
	display: flex;
	justify-content: flex-start;
`;
const RadioButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	border: 2px solid ${COLORS.THEME_COLOR};
	border-radius: 5px;
`;
const Tick = styled(DoneOutlinedIcon)`
	font-size: 14px !important;
	color: ${COLORS.THEME_COLOR};
`;
const Content = styled.div`
	margin-left: 10px;
`;
const ContentLabel = styled.p`
	font-size: 15px;
	color: ${COLORS.PRIMARY_TEXT};
	margin-bottom: 6px;
`;
const ContantDescription = styled.p`
	font-size: 14px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const FormSection = styled.form`
	display: block;
	margin-top: 50px;
`;
const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	position: relative;
`;

const InputLabel = styled.label`
	font-size: 16px;

	color: ${COLORS.SECONDARY_TEXT};
`;

const TextInput = styled.input`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
`;

const TextArea = styled.textarea`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	height: 100px;
	width: 60%;
	border: 1px solid #eeeeee;
`;

const BrandSelect = styled.select`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
`;
const Error = styled.p`
	font-size: 12px;
	color: red;
	bottom: -20px;
	right: 0;
	position: absolute;
`;

const BasicInput = styled.div``;
const GeneralInput = styled.div``;
const SectionBreaker = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
`;
const SectionTitle = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 16px;
	margin-right: 20px;
`;
const Line = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	span {
		display: block;
		flex: 1;
		border-bottom: 1px solid #f0f0f0;
	}
`;
const ShippingInput = styled.div``;
const AttributeInput = styled.div``;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
	position: absolute;
	width: 100%;
	bottom: 0;
	right: 0;
	border-top: 1px solid ${COLORS.SECONDARY};
	padding-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
`;
