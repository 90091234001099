import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants";
import dashbordIcon from "../../assets/images/Setting.svg";
import order from "../../assets/images/Delivery.svg";
import clipIcon from "../../assets/images/clipboard-text.svg";
import money from "../../assets/images/Money.svg";
import payment from "../../assets/images/payment.svg";
import packageIcon from "../../assets/images/product.svg";
import vendorIcon from "../../assets/images/vendorIcon.svg";
import vendorProductsIcon from "../../assets/images/vendorProduct.svg";
import vendorOrderIcon from "../../assets/images/vendorOrder.svg";
import paymentRequestIcon from "../../assets/images/wallet-add.svg";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // We're creating an effect to update screenWidth whenever there is a resize
  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const activeMenu = useSelector((state) => state.admin.activeMenu);

  return (
    <Container
      showSidebar={showSidebar}
      screenWidth={screenWidth}
      onMouseEnter={() => setShowSidebar(true)}
      onMouseLeave={() => setShowSidebar(false)}
    >
      <Content>
        <ul>
          <li>
            <Link
              to="/"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "dashboard" ? "active" : null}
            >
              <img src={dashbordIcon} alt="Dashboard Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Dashboard"}
            </Link>
          </li>
          <li>
            <Link
              to="/vendors"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "vendors" ? "active" : null}
            >
              <img src={vendorIcon} alt="Dashboard Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Vendors"}
            </Link>
          </li>
          <li>
            <Link
              to="/vendor-products"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "Vendors Products" ? "active" : null}
            >
              <img src={vendorProductsIcon} alt="Dashboard Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Vendors Products"}
            </Link>
          </li>
          <li>
            <Link
              to="/vendor-orders"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "Vendor Orders" ? "active" : null}
            >
              <img src={vendorOrderIcon} alt="Dashboard Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Vendor Orders"}
            </Link>
          </li>
          <li>
            <Link
              to="/payment-request"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "Payment Request" ? "active" : null}
            >
              <img src={paymentRequestIcon} alt="Dashboard Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Payment Request"}
            </Link>
          </li>
          <Divider />
          <li>
            {/* Use an <Link> so that we can detect which link is active */}
            <Link
              to="/orders"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "orders" ? "active" : null}
            >
              <img src={order} alt="Home Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Orders"}
            </Link>
          </li>
          <li>
            <Link
              to="/attributes"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "attributes" ? "active" : null}
            >
              <img src={money} alt="Reports Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Attributes"}
            </Link>
          </li>
          <li>
            <Link
              to="/products"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "products" ? "active" : null}
            >
              <img src={packageIcon} alt="Settings Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Products"}
            </Link>
          </li>
          <li>
            <Link
              to="/inventory"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "inventory" ? "active" : null}
            >
              <img src={clipIcon} alt="Settings Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Inventory"}
            </Link>
          </li>
          <li>
            <Link
              to="/payments"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "payments" ? "active" : null}
            >
              <img src={payment} alt="Settings Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Payments"}
            </Link>
          </li>
          <li>
            <Link
              to="/settings"
              onClick={() => setShowSidebar(false)}
              className={activeMenu === "settings" ? "active" : null}
            >
              <img src={dashbordIcon} alt="Settings Icon" />{" "}
              {(screenWidth > 1100 || showSidebar) && "Settings"}
            </Link>
          </li>
        </ul>
      </Content>
    </Container>
  );
};
export default Sidebar;

// Styling with styled components
const Container = styled.div`
  background-color: ${COLORS.PRIMARY};
  height: calc(100vh - 70px);
  width: ${(props) => (props.showSidebar ? "220px" : "60px")};
  min-width: ${(props) => (props.showSidebar ? "220px" : "60px")};
  /* position: fixed;
	top: 80px;
	left: 0;
	z-index: 10; */
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 1100px) {
    width: 220px;
  }
`;

const Content = styled.div`
  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;

      img {
        height: 20px;
        margin-right: 10px;
      }

      a {
        padding: 10px;
        margin: 5px;
        border-radius: 5px;
        width: 100%;
        text-decoration: none;
        color: ${COLORS.SECONDARY_TEXT};
        font-size: 14px;
        display: flex;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          display: none;
          position: absolute;
          height: 60%;
          width: 3px;
          background-color: ${COLORS.PRIMARY_TEXT};
          left: 1px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 0 2px 2px 0;
        }

        &:hover {
          background: linear-gradient(
            340.8deg,
            rgba(255, 223, 23, 0.45) 18.2%,
            rgba(255, 247, 158, 0.45) 167.34%
          );

          color: ${COLORS.PRIMARY_TEXT};
        }

        &.active {
          background: linear-gradient(
            340.8deg,
            rgba(255, 223, 23, 0.45) 18.2%,
            rgba(255, 247, 158, 0.45) 167.34%
          );

          color: ${COLORS.PRIMARY_TEXT};
          ::before {
            display: block;
          }
        }
      }

      @media (max-width: 1100px) {
        img {
          height: 25px;
          margin-right: 20px;
        }

        a span {
          display: none;
        }
      }
    }
  }
`;
